import { Text, Heading, Box, Flex, Menu, Button, MenuButton, MenuList, MenuItem, MenuDivider, useDisclosure, useToast, Spinner } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseMutateFunction, useQueryClient } from '@tanstack/react-query';

import { RTyreList, RAlert, RLoadingAnimation, RToast } from 'components';
import { Tyres } from 'types';
import {
  SetCarPartMutation,
  MutationSetCarPartsArgs,
  Races,
} from 'api/generated/graphql';
import EngineAndTuning, { EngineAndTuningProps } from './EngineAndTuning';
import { useGetRaceById, useGetSavedSetups, useTuningForRace, useUserSettings } from 'hooks';
import { IconCheckGreen, IconChevron, IconEmptySet, IconPlusCircle, IconTrashCan } from 'icons';
import SavedSetupCarModal from 'components/SavedSetups/SavedSetupCarModal';
import SavedSetupDeleteModal from 'components/SavedSetups/SavedSetupDeleteModal';

const SelectTyres = ({
  setSelectedTyres,
  selectedTyres,
  carId,
  mutateCarPart,
  ...engineAndTuningProps
}: EngineAndTuningProps & {
  setSelectedTyres: Dispatch<SetStateAction<Tyres>>;
  selectedTyres: Tyres;
  carId: string;
  mutateCarPart: UseMutateFunction<
    SetCarPartMutation,
    unknown,
    MutationSetCarPartsArgs,
    unknown
  >;
}) => {
  const { getUserSettings } = useUserSettings();
  const toast = useToast();
  const [selectedSavedSetup, setSelectedSavedSetup] = useState<any>(null);
  const {
    isOpen: isOpenSavedSetupModal,
    onOpen: onOpenSavedSetupModal,
    onClose: onCloseSavedSetupModal,
  } = useDisclosure();

  const {
    isOpen: isOpenSavedSetupDeleteModal,
    onOpen: onOpenSavedSetupDeleteModal,
    onClose: onCloseSavedSetupDeleteModal,
  } = useDisclosure();

  const { data: raceByIdData } = useGetRaceById({
    raceId: engineAndTuningProps.tuningSelections.raceId,
  });
  const race = raceByIdData?.getRaceById as Races;
  const userRaceData = race?.playersParticipants?.find(
    (player) => player.user.id === getUserSettings.data?.getUser.id
  );
  const queryClient = useQueryClient();

  const { data: savedSetupsData, isLoading: isLoadingSavedSetupsData } = useGetSavedSetups({ carId: carId });
  const { tuningForRace } = useTuningForRace();
  const { mutateAsync: mutateTuning, isLoading: isLoadingTuning } = tuningForRace;

  const filteredSavedSetups = savedSetupsData?.getSavedSetups.filter(setup => {
    const conditions = [
      setup.frontWingTuning !== null,
      setup.rearWingTuning !== null,
      setup.frontWingId === engineAndTuningProps.parts.frontWing?.id,
      setup.rearWingId === engineAndTuningProps.parts.rearWing?.id
    ];

    if (engineAndTuningProps.parts.brakeCooling?.id) {
      conditions.push(setup.brakeCoolingId === engineAndTuningProps.parts.brakeCooling.id);
    }

    if (engineAndTuningProps.parts.engineCooling?.id) {
      conditions.push(setup.engineCoolingId === engineAndTuningProps.parts.engineCooling.id);
    }

    if (engineAndTuningProps.parts.transmissionRatio?.id) {
      conditions.push(setup.transmissionId === engineAndTuningProps.parts.transmissionRatio.id);
    }

    return conditions.every(condition => condition);
  });


  const handleApplySavedSetup = async (savedSetup: any) => {
    const setupParts = {
      frontWingTuning: savedSetup?.frontWingTuning,
      rearWingTuning: savedSetup?.rearWingTuning,
      brakeCoolingTuning: savedSetup?.brakeCoolingTuning,
      engineCoolingTuning: savedSetup?.engineCoolingTuning,
      transmissionTuning: savedSetup?.transmissionTuning,
      tyresId: savedSetup?.tyresId
    };

    const filteredSetupParts = Object.fromEntries(
      Object.entries(setupParts).filter(([_, value]) => value !== undefined)
    );

    try {
      await mutateTuning({ raceTuningInput: { ...filteredSetupParts, raceId: engineAndTuningProps.tuningSelections.raceId } });
      queryClient.invalidateQueries({
        queryKey: ['raceByIdQuery', engineAndTuningProps.tuningSelections.raceId],
      });
      toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="success"
            title={`Saved Setup applied`}
          />
        ),
      });
    } catch (error) {
      console.error("Error applying saved setup:", error);
      toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title={`Error applying saved setup`}
          />
        ),
      });
    }
  }

  const handleSelectSavedSetup = async (savedSetup: any) => {
    setSelectedSavedSetup(savedSetup);
    await handleApplySavedSetup(savedSetup);
  }


  return (
    <Box height="fit-content" pb="20">
      <Flex
        justify="space-between"
        align="center"
        mb="6"
      >
        <Heading as="h1" size="lg" textTransform="uppercase">
          Car Setup
        </Heading>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={
              <IconChevron __css={{ transform: 'rotate(90deg)' }} ml={4} />
            }
            variant="secondary"
            textTransform="none"
            h="34px"
            fontSize="14px"
          >
            {selectedSavedSetup ? selectedSavedSetup.name : 'Saved Tuning & Tyre Setups'}
          </MenuButton>
          <MenuList
            w="full"
            bg="darkVoid.100"
            p="4"
            zIndex={10}
          >
            <MenuItem
              bg="darkVoid.100"
            >
              <Flex
                flexDir="column"
                gap="2"
              >
                <Flex
                  align="center"
                  gap="2"
                  justify="space-between"
                  color="white.80"
                  w="full"
                  minW="35rem"
                  onClick={onOpenSavedSetupModal}
                >
                  <Flex
                    align="center"
                    gap="4"
                  >
                    <IconPlusCircle 
                      __css={{
                        path: { fill: 'white.80' },
                      }}
                    />
                    <Text
                      fontFamily="heading"
                    >
                      New Saved Setup
                    </Text>
                  </Flex>
                  <IconChevron />
                </Flex>
                <Flex
                  mt="1rem"
                  w="full"
                  maxW="30rem"
                  margin="auto"
                >
                  <Text
                    fontSize="14px"
                    color="white.60"
                    fontFamily="body"
                  >
                    New Saved Setups only apply to the currently selected car and parts within Parts Setup
                  </Text> 
                </Flex>
              </Flex>
            </MenuItem>
            <MenuDivider />
            {isLoadingSavedSetupsData ? (
              <MenuItem bg="darkVoid.100">
                <Flex align="center" justify="center" w="full">
                  <Spinner color="white.80" size="md" />
                </Flex>
              </MenuItem>
            ) : filteredSavedSetups?.length === 0 ? (
              <MenuItem
                bg="darkVoid.100"
              >
                <Flex
                  align="center"
                  gap="4"
                  color="white.80"
                >
                  <IconEmptySet
                    width="20px"
                    height="20px"
                    __css={{
                      path: { fill: 'white.80' },
                    }}
                  />
                  <Text
                    fontFamily="heading"
                  >
                    No Saved Setups
                  </Text>
                </Flex>
              </MenuItem>
            ) : (
              filteredSavedSetups && filteredSavedSetups.map((savedSetup) => (
                <MenuItem
                  bg="darkVoid.100"
                >
                  <Flex
                    align="center"
                    justify="space-between"
                  >
                    <Flex
                      align="center"
                      gap="2"
                      color="white.80"
                      w="full"
                      minW="34rem"
                      onClick={() => handleSelectSavedSetup(savedSetup)}
                    >
                      <Flex
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedSavedSetup(savedSetup);
                          onOpenSavedSetupDeleteModal();
                        }}
                      >
                        <IconTrashCan
                          __css={{
                            path: { fill: 'white.80' },
                          }}
                        />
                      </Flex>
                      <Text
                        fontFamily="heading"
                      >
                        {savedSetup.name}
                      </Text>
                    </Flex>
                    {selectedSavedSetup?.id === savedSetup.id && (
                      <IconCheckGreen />
                    )}
                  </Flex>
                </MenuItem>
              ))
            )}  
          </MenuList>
        </Menu>
      </Flex>

      <Heading as="h2" size="md" textTransform="uppercase" mb="3">
        Tyres
      </Heading>

      <Box mb="8">
        <Text mb="4" fontSize="0.875rem" color="white.80">
          Softer tyres have high peak grip but struggle in high temperatures and
          have a short lifespan. Treaded intermediate and wet tyres only work in
          the rain.
        </Text>

        <RAlert
          variant="info"
          description="Please note: Tyre selection won’t effect the qualifying lap."
        />
      </Box>

      <RTyreList
        raceId={engineAndTuningProps.tuningSelections.raceId}
        setSelectedTyres={setSelectedTyres}
        selectedTyres={(userRaceData?.tyres?.id || 'C2') as Tyres}
        carId={carId}
        mutateCarPart={mutateCarPart}
      />

      {isLoadingTuning && (
          <Flex
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="9999"
            bg="rgba(0, 0, 0, 0.5)"
            alignItems="center"
            justifyContent="center"
          >
            <RLoadingAnimation />
          </Flex>
        )}

      <EngineAndTuning
        selectedTyres={selectedTyres}
        {...engineAndTuningProps}
      />

      <SavedSetupCarModal 
        isOpen={isOpenSavedSetupModal}
        onClose={onCloseSavedSetupModal}
        tuningSelections={engineAndTuningProps.tuningSelections}
        carId={carId}
        userRaceData={userRaceData}
      />
      <SavedSetupDeleteModal
        isOpen={isOpenSavedSetupDeleteModal}
        onClose={onCloseSavedSetupDeleteModal}
        savedSetupId={selectedSavedSetup?.id}
        carId={carId}
        savedSetupName={selectedSavedSetup?.name}
        setSelectedSavedSetup={setSelectedSavedSetup}
      />
    </Box>
  );
};

export default SelectTyres;
