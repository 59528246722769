import { Flex, Heading, UnorderedList, ListItem, Text } from '@chakra-ui/react';
import AidrianaImage from 'components/Aidriana/Image';
import { defaultGradient } from 'utils/themeConstants';

interface AidrianaTipsProps {
  tips: string[] | undefined;
  header?: boolean;
}

export const AidrianaTips = ({ tips, header = true }: AidrianaTipsProps) => {
  return (
    <Flex px={4} w="full" gap={4} py={4}>
      <AidrianaImage
        width={140}
        height={154}
        alt="Aidriana"
        bg={defaultGradient(100)}
      />
      <Flex flexDir="column" gap={2} maxH="150px" overflow="auto">
        <Heading size="md" color="white.80" fontWeight="normal">
          Aidriana
        </Heading>
        {header && (
          <Text fontSize="md" mb="4">
            Here&apos;s some general feedback from the race and a comparison of
            stats and parts.
          </Text>
        )}

        <UnorderedList>
          {tips && tips?.length > 0 ? (
            <>
              {tips?.map((tip) => (
                <ListItem
                  key={tip}
                  background="rgba(255, 255, 255, 0.1)"
                  fontFamily={'heading'}
                  textTransform="uppercase"
                  p="2"
                  mb="0.5rem"
                  fontSize="xs"
                  color="white.80"
                  borderRadius="sm"
                  borderTopLeftRadius="0"
                  listStyleType="none"
                  position="relative"
                  _before={{
                    content: '""',
                    position: 'absolute',
                    top: '0px',
                    left: '-5px',
                    borderTop: '1px solid transparent',
                    borderBottom: '5px solid transparent',
                    borderRight: '5px solid rgba(255, 255, 255, 0.1)',
                  }}
                >
                  {tip}
                </ListItem>
              ))}
            </>
          ) : (
            <>No race tips.</>
          )}
        </UnorderedList>
      </Flex>
    </Flex>
  );
};
