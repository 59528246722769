import pick from 'lodash.pick';

import { Cars, CarParts } from 'api/generated/graphql'; // eslint-disable-line no-restricted-imports
import { CarPart, PartTypes } from 'types';

/**
 * Get all parts from a car object
 */
const pickCarParts = (car: Cars): CarPart[] => {
  return Object.entries(
    pick(car, [
      'frontWing',
      'rearWing',
      'engineCooling',
      'brakeCooling',
      'transmissionRatio',
      'tyres',
    ])
  )
    .map((entry) => {
      return {
        partType: entry[0] as PartTypes,
        ...(entry[1] as CarParts),
      };
    })
    .filter((part) => part.id) as CarPart[];
};

export default pickCarParts;
