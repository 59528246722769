import { useEffect, useState } from 'react';
import {
  Button,
  Collapse,
  Flex,
  Grid,
  Heading,
  Text,
  UseDisclosureProps,
  useDisclosure,
} from '@chakra-ui/react';

import { RAlert, RDrawer, RPartCard } from 'components';
import { useGetGarage, useGetTicketsAvailable, useRepairCarParts } from 'hooks';
import { IconTicket, IconWrenchScrewdriver } from 'icons';
import { CarPart } from 'types';
import RepairPartsModal from 'components/RepairPartsModal/RepairPartsModal';
import { useQueryClient } from '@tanstack/react-query';
import { useAnimatedTransition } from 'context';
import { PartTypeName } from 'api/generated/graphql';


interface RepairPartsDrawerProps extends UseDisclosureProps {
  carId?: string;
  maximumTimesUsed: number | null;
  requiredRepairPart?: PartTypeName;
}

const carPartsObj: CarPart[] = [
  {
    partType: 'frontWing',
    id: 'Front Wing',
    name: 'Front Wing',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'rearWing',
    id: 'Rear Wing',
    name: 'Rear Wing',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'engineCooling',
    id: 'Engine Cooling',
    name: 'Engine Cooling',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'brakeCooling',
    id: 'Brake Cooling',
    name: 'Brake Cooling',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'transmissionRatio',
    id: 'Transmission Ratio',
    name: 'Transmission Ratio',
    locked: false,
    rankRequired: 0,
  },
]

const RepairPartsDrawer = ({
  carId = '',
  maximumTimesUsed,
  isOpen = false,
  onClose = () => null,
  requiredRepairPart,
}: RepairPartsDrawerProps) => {
  const [selectedCarPart, setSelectedCarPart] = useState<CarPart>();

  const { showAnimatedTransition } = useAnimatedTransition();

  const {
    onClose: modalClose,
    isOpen: modalOpen,
    onOpen: modalOnOpen,
  } = useDisclosure();

  const queryClient = useQueryClient();
  const { data } = useGetTicketsAvailable();
  const { mutateAsync, isLoading } = useRepairCarParts();

  const { data: garageData } = useGetGarage();
  const car = garageData?.getGarage?.find((car) => car.id === carId);

  const handleSelectCarPart = (part: CarPart) => {
    setSelectedCarPart(part);
    console.log(part)
  };

  const handleClose = () => {
    setSelectedCarPart(undefined);
    onClose();
  };

  const formatTicketText = (count: number) => {
    const ticketCount = count || 1;
    return `${ticketCount} ticket${ticketCount > 1 ? 's' : ''}`;
  };

  const handleRepairCarParts = async () => {
    await mutateAsync({
      carId,
      partName: 
      selectedCarPart?.partType === 'transmissionRatio' ? 'transmission' as PartTypeName 
      : selectedCarPart?.partType as PartTypeName,
    });

    queryClient.invalidateQueries({
      queryKey: ['garageQuery'],
    });
    queryClient.invalidateQueries({
      queryKey: ['ticketsAvailable'],
    });

    showAnimatedTransition({
      Icon: IconWrenchScrewdriver,
      id: 'car-repaired-success',
      title: 'Car',
      subtitle: 'Repaired',
      color: 'treasuredTeal.100',
      bgColor:
        'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
      gradientColor: {
        from: 'rgba(72, 223, 187, 0.2)',
        to: 'rgba(72, 223, 187, 0)',
      },
    });

    handleClose();
  };

  useEffect(() => {
    if (requiredRepairPart) {
      setSelectedCarPart(carPartsObj.find((part) => part.partType === requiredRepairPart));
    }
  }, [requiredRepairPart]);


  return (
    <>
      <RDrawer
        heading="Repair Parts"
        isOpen={isOpen}
        onClose={handleClose}
        drawerBody={
          <Flex w="full" flexDirection="column">
            <Flex
              m="2rem 0"
              alignItems="center"
              justifyContent="space-between"
              h="2.25rem"
              w="full"
            >
              <Text mb="2">
                Parts are required to be repaired at each interval, randomizing their attributes, and presenting players with a fresh set of challenges.
                Hit the track, and see what you're working with!
              </Text>
            </Flex>

            {requiredRepairPart && (
              <Collapse in={true}>
                <RAlert
                  mb="4"
                  variant="warning"
                  description={
                    `You need to repair the ${requiredRepairPart.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} part to reset your repair counter.`
                  }
                />
              </Collapse>
            )}

            <Grid gridTemplateColumns="50% 50%" gap="2" mb="20">
              {carPartsObj
                .map((part) => {
                  const isApplied = selectedCarPart?.id === part.id
                  return (
                    <RPartCard
                      key={part.id}
                      flexBasis="49%"
                      h="9.25rem"
                      carPart={{ ...part, isApplied }}
                      onClick={() => handleSelectCarPart(part)}
                    />
                  );
                })}
            </Grid>
          </Flex>
        }
        drawerFooter={
          <Flex
            w="full"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              fontSize="1rem"
              textTransform="uppercase"
              mb="1.5rem"
              fontWeight="normal"
            >
              {data?.ticketsAvailable || 0} Tickets Available
            </Heading>
            <Button
              w="full"
              paddingY="1.5rem"
              variant="tertiary"
              isDisabled={
                data?.ticketsAvailable === 0 || !selectedCarPart
              }
              isLoading={isLoading}
              onClick={modalOnOpen}
            >
              <IconTicket mr="0.4rem" w="2rem" h="2rem" />
              Repair for 1 ticket
            </Button>
          </Flex>
        }
      />
      <RepairPartsModal
        isOpen={modalOpen}
        onClose={modalClose}
        confirmFn={handleRepairCarParts}
        parts={selectedCarPart?.partType}
      />
    </>
  );
};

export default RepairPartsDrawer;
