import { useQuery } from '@tanstack/react-query';
import { Status, Tier } from 'api/generated/graphql';

import { useGraphQLClient } from 'hooks';

const useGetNextUpRacesByTierAndStatus = ({
  refetchInterval,
  tier = [Tier.Free, Tier.Bronze, Tier.Silver, Tier.Gold, Tier.Platinum],
  status = [
    Status.Open,
    Status.Tuning,
    Status.Closed,
    Status.Qualifying,
    Status.Live,
  ],
}: {
  refetchInterval?: number;
  tier?: Tier[];
  status?: Status[];
}) => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const racesGql = graphql(`
    query GetNextUpRacesByTier($Tier: [tier!], $Status: [status!]) {
      getRaces(filter: { status: $Status, tier: $Tier, limit: 500 }) {
        id
        laps
        prizePool
        status
        userInRace

        airTemperatureCategory
        trackTemperatureCategory
        weatherCategory

        createdAt
        executedAt
        tuningPeriodEndTime
        qualifyingAnnouncementTime
        startTime
        numberOfTickets
        currency

        tier {
          name
        }

        track {
          id
          frontendName
          codeName
          length
          description
          has2D
          has3D
        }
      }
    }
  `);

  const getNextUpRacesByTierAndStatus = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['nextUpRacesByTierAndStatusQuery'],
    refetchInterval,
    queryFn: async () => {
      return graphQLClient.request(racesGql, {
        Tier: tier,
        Status: status,
      });
    },
  });

  return getNextUpRacesByTierAndStatus;
};

export default useGetNextUpRacesByTierAndStatus;
