import { useMutation } from '@tanstack/react-query';
import { useGraphQLClient } from 'hooks';

const useRedeemSingleTrailer = () => {
  const { graphQLClient, graphql } = useGraphQLClient();

  const redeemSingleTrailerGQL = graphql(`
    mutation RedeemSingleTrailer($walletAddress: String, $tokenId: String!, $source: String) {
      redeemSingleTrailer(walletAddress: $walletAddress, tokenId: $tokenId, source: $source) {
        externalWalletAddress
        tokenId
        status
        referenceId
      }
    }
  `);

  const RedeemSingleTrailer = useMutation({
    mutationKey: ['redeemSingleTrailer'],
    mutationFn: async ({
      walletAddress,
      tokenId,
      source,
    }: {
      walletAddress?: string;
      tokenId: string;
      source?: string;
    }) => {
      return graphQLClient.request(redeemSingleTrailerGQL, {
        walletAddress,
        tokenId,
        source,
      });
    },
  });

  return RedeemSingleTrailer;
};

export default useRedeemSingleTrailer;
