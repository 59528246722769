/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  UseDisclosureProps,
  ButtonGroup,
  ButtonProps,
  Alert,
  Skeleton,
  Heading,
  useToast,
  ToastId,
  Show,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState, ReactElement, useRef, useEffect } from 'react';
import pick from 'lodash.pick';
import { useQueryClient } from '@tanstack/react-query';

import {
  RaceTuningInput,
  Races,
  Cars,
  CurrencyType,
} from 'api/generated/graphql';
import {
  RDrawer,
  RRaceStatus,
  RRaceInfo,
  RTiers,
  RDateWithLabel,
  RLoadingAnimation,
  RToast,
  RAlert,
} from 'components';
import { RaceTier, Tyres, SetupSuggestions } from 'types';
import { IconArrow, IconCheckeredFlag } from 'icons';
import {
  useTuningForRace,
  useEnterRace,
  useGetGarage,
  useGetRaceById,
  useRaceCountdown,
  useSetCarPart,
  useGetTicketsAvailable,
  useUserSettings,
  useGetUnityUrl,
} from 'hooks';
import { useAnimatedTransition, useTuning } from 'context';
import DrawerPages from './DrawerPages';
import { racePreviewGradient } from 'utils/themeConstants';
import OverallTuning from 'components/OverallTuning/OverallTuning';
import { isMobileDevice } from 'utils/helpers';
import { useRouter } from 'next/router';

const NextButton = ({
  children,
  isDisabled,
  onClick,
  ...rest
}: ButtonProps) => {
  return (
    <Button
      w="full"
      isDisabled={isDisabled}
      textAlign="center"
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

type RaceDrawerProps = {
  raceId: string;
};

type PanelIdentifiers =
  | 'raceDetails'
  | 'selectCar'
  | 'selectTyres'
  | 'admissionUse'
  | 'buyTickets'
  | 'liveRace';

type DrawerPanelsType = {
  [key in PanelIdentifiers]?: JSX.Element;
};

// TODO consider a context for child props
const RacePreviewDrawer = ({
  raceId = '',
  isOpen = false,
  onClose = () => null,
}: UseDisclosureProps & RaceDrawerProps) => {
  const queryClient = useQueryClient();
  const { getUserSettings } = useUserSettings();
  const { showAnimatedTransition } = useAnimatedTransition();
  const { handling, cooling, speed, braking, grip, fuel, isLoadingTuning } =
    useTuning();
  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);
  const router = useRouter();

  const {
    isOpen: isOpenAdmissionUse,
    onOpen: onOpenAdmissionUse,
    onClose: onCloseAdmissionUse,
  } = useDisclosure();

  const tuningValues = {
    handling,
    cooling,
    speed,
    braking,
    grip,
    fuel,
  };

  // queries
  const { data: garageData, isLoading: isLoadingGarage } = useGetGarage();
  const {
    data: raceByIdData,
    isLoading: isLoadingRaceById,
    isSuccess: isSuccessRaceById,
  } = useGetRaceById({
    raceId,
  });
  const { data: getTicketsAvailabeData } = useGetTicketsAvailable();
  const racinoTicketsAvailable = getTicketsAvailabeData?.ticketsAvailable || 0;
  const vextTicketsAvailable =
    getTicketsAvailabeData?.vextTicketsAvailable || 0;

  // mutations
  const { enterRace } = useEnterRace();
  const { tuningForRace } = useTuningForRace();
  const setCarPart = useSetCarPart();

  // data prep
  const race = raceByIdData?.getRaceById as Races;

  const ticketsAvailable =
    race?.currency === CurrencyType.Vext
      ? vextTicketsAvailable
      : racinoTicketsAvailable;
  const numberOfTickets = race?.numberOfTickets || 0;
  const rawSuggestions = race?.setupSuggestions;
  const setupSuggestions: SetupSuggestions =
    rawSuggestions && JSON.parse(rawSuggestions);
  const carsInGarage = garageData?.getGarage as Cars[];
  const { raceStatus } = useRaceCountdown({
    currentStatus: race?.status,
    startTime: race?.startTime,
  });

  const { url } = useGetUnityUrl({
    raceId,
    userId: getUserSettings.data?.getUser.id,
  });

  const userRaceData = race?.playersParticipants?.find(
    (player) => player.user.id === getUserSettings.data?.getUser.id
  );

  // state
  const [panel, setPanel] = useState<PanelIdentifiers>('raceDetails');
  const [selectedCarId, setSelectedCarId] = useState<string | undefined>(
    userRaceData?.car?.id || carsInGarage?.[0]?.id
  );
  const [selectedTyres, setSelectedTyres] = useState<Tyres>(
    (userRaceData?.tyres?.id || 'C2') as Tyres
  );
  const [canWatchRace, setCanWatchRace] = useState(false);
  const selectedCar = carsInGarage?.find((car) => car.id === selectedCarId);


  // console.log('===> UserRaceData <===', userRaceData);


  const [tuningSelections, setTuningSelections] = useState<RaceTuningInput>({
    raceId
  });
  
  useEffect(() => {
    setSelectedCarId(userRaceData?.car.id || carsInGarage?.[0]?.id)
    setTuningSelections({
      raceId: raceId,
      frontWingTuning: userRaceData?.frontWingTuning,
      rearWingTuning: userRaceData?.rearWingTuning,
      engineModeTuning: userRaceData?.engineModeTuning,
      engineCoolingTuning: userRaceData?.engineCoolingTuning,
      brakeCoolingTuning: userRaceData?.brakeCoolingTuning,
      transmissionTuning: userRaceData?.transmissionTuning,
      suspensionStiffnessTuning: userRaceData?.suspensionStiffnessTuning,
      suspensionRideHeightTuning: userRaceData?.suspensionRideHeightTuning
    }
    )
  }, [userRaceData])

  // display any errors while joining race
  const [raceAlert, setRaceAlert] = useState<
    ReactElement<typeof Alert> | undefined
  >(undefined);
  const [userInRace, setUserInRace] = useState<boolean>(false);

  const canEnterRace =
    !userInRace && (raceStatus === 'open' || raceStatus === 'tuning');
  const canTune =
    userInRace && (raceStatus === 'open' || raceStatus === 'tuning');
  const isLive = raceStatus === 'live';
  const liveRacePanel =
    (canTune ||
      !canEnterRace ||
      race?.status === 'live' ||
      race?.status === 'qualifying') &&
    (panel === 'liveRace' || panel === 'raceDetails');

  const handleEnterRaceErrors = () => {
    const enterRaceError = enterRace?.error as { message: string };

    const formatErrorMessage = (errorMessage: string) => {
      const braceIndex = errorMessage.indexOf('{');
      if (braceIndex > -1) {
        return errorMessage.substring(0, braceIndex - 2);
      }

      return errorMessage;
    };

    const errorMessage: string | undefined = enterRaceError?.message
      ? formatErrorMessage(enterRaceError.message)
      : undefined;

    const isCarScheduledError =
      enterRaceError && errorMessage?.startsWith('Car already in other race');

    const isOtherRaceError = enterRaceError && !isCarScheduledError;

    if (!raceAlert && isCarScheduledError) {
      setRaceAlert(
        <RAlert
          variant="warning"
          description="Car already scheduled to race. After completion, you can enter another race."
        />
      );
      return true;
    }

    if (!raceAlert && isOtherRaceError) {
      if (errorMessage)
        setRaceAlert(<RAlert variant="error" description={errorMessage} />);
      else
        setRaceAlert(
          <RAlert
            variant="error"
            description="There was an error entering the race."
          />
        );
      return true;
    }

    return false;
  };

  useEffect(() => {
    const hasError = handleEnterRaceErrors();

    console.log(hasError);

    if (hasError) {
      setPanel('selectCar');
    }
  }, [enterRace?.error]);

  useEffect(() => {
    if (race?.userInRace) setUserInRace(true);
    if (!race?.userInRace) setUserInRace(false);
  }, [race?.userInRace]);

  useEffect(() => {
    if (!race?.startTime) return;

    const now = Date.now();
    const raceStartTime = new Date(race?.startTime).getTime();
    const timeDifferenceInMinutes = (raceStartTime - now) / 60000;

    if (timeDifferenceInMinutes <= 5) {
      setCanWatchRace(true);
    } else {
      const timer = setTimeout(() => {
        setCanWatchRace(true);
      }, (timeDifferenceInMinutes - 5) * 60000);

      return () => clearTimeout(timer);
    }
  }, [race?.startTime]);

  const getPanelKey = (
    currentPanel: PanelIdentifiers,
    change: number
  ): PanelIdentifiers => {
    const flow: PanelIdentifiers[] = [
      'raceDetails',
      'selectCar',
      'admissionUse',
      'buyTickets',
      'selectTyres',
    ];

    const currentIndex = flow.indexOf(currentPanel);

    let nextIndex = currentIndex + change;
    nextIndex = Math.max(0, Math.min(flow.length - 1, nextIndex));

    if (change > 0) {
      if (currentPanel === 'raceDetails' && canTune) {
        return 'selectTyres';
      }
      if (
        currentPanel === 'admissionUse' &&
        ticketsAvailable >= numberOfTickets
      ) {
        return 'selectTyres';
      }
    }
    if (change < 0) {
      if (currentPanel === 'selectTyres') {
        return 'raceDetails';
      }
    }

    return flow[nextIndex];
  };

  const updatePanel = (change: number) => {
    const nextPanelKey = getPanelKey(panel, change);
    if (nextPanelKey) {
      document.getElementById('drawerBody')?.scrollTo(0, 0);
      setPanel(nextPanelKey);

      queryClient.refetchQueries({
        queryKey: ['ticketsAvailable'],
      });
    }
  };

  const prevPanel = () => updatePanel(-1);
  const nextPanel = () => updatePanel(1);

  const renderPanel = (
    isLoading: boolean,
    PanelComponent: (props: any) => JSX.Element,
    props: any
  ) =>
    isLoading ? (
      <Flex w="full" h="full" alignItems="center" justifyContent="center">
        <RLoadingAnimation />
      </Flex>
    ) : (
      <PanelComponent {...props} />
    );

  const allPanels = {
    raceDetails: renderPanel(isLoadingRaceById, DrawerPages.RaceDetails, {
      race,
    }),
    admissionUse: renderPanel(isLoadingRaceById, DrawerPages.AdmissionUse, {
      currency: race?.currency,
      admissionFee: numberOfTickets,
      cost: race?.prizePool,
      isOpen: isOpenAdmissionUse,
      onOpen: onOpenAdmissionUse,
      onClose: onCloseAdmissionUse,
    }),
    buyTickets: renderPanel(isLoadingRaceById, DrawerPages.BuyTickets, {
      currency: race?.currency,
      handleNextTab: nextPanel,
      numberOfTickets,
    }),
    selectCar: renderPanel(
      isLoadingGarage && enterRace.isLoading,
      DrawerPages.SelectCar,
      {
        setSelectedCarId,
        cars: carsInGarage,
        raceAlert,
        raceName: race?.track?.frontendName,
      }
    ),
    selectTyres: renderPanel(
      isLoadingGarage || isLoadingRaceById,
      DrawerPages.SelectTyres,
      {
        selectedTyres,
        setSelectedTyres,
        carId: selectedCarId,
        mutateCarPart: setCarPart.mutate,
        tuningSelections,
        setTuningSelections,
        parts: userRaceData,
        mutateTuning: tuningForRace.mutate,
        tuningError: tuningForRace.error,
        setupSuggestions,
      }
    ),
    liveRace: renderPanel(isLoadingRaceById, DrawerPages.LiveRace, {
      race: race,
    }),
  };

  let drawerPanels: DrawerPanelsType = canEnterRace
    ? allPanels
    : { raceDetails: allPanels.liveRace };

  if (canTune) {
    drawerPanels = {
      raceDetails: allPanels.liveRace,
      admissionUse: allPanels.admissionUse,
      selectTyres: allPanels.selectTyres,
    };
  }

  if (race?.status === 'live' || race?.status === 'qualifying') {
    drawerPanels = {
      raceDetails: allPanels.liveRace,
    };
  }

  const isEnteringRace =
    selectedCarId &&
    raceId &&
    panel === 'admissionUse' &&
    ticketsAvailable >= numberOfTickets;

  // if (
  //   enterRace.isSuccess &&
  //   enterRace.data?.enterRace.status === 'accepted' &&
  //   panel === 'selectCar'
  // ) {
  //   queryClient.refetchQueries({
  //     queryKey: ['nextUpRacesByTierAndStatusQuery'],
  //   });
  //   nextPanel();
  // }

  let buttonText = 'Next';
  if (panel === 'selectTyres') {
    buttonText = 'Enter Qualification';
  }
  if (panel === 'admissionUse') {
    if (ticketsAvailable >= numberOfTickets)
      buttonText = `Use ${numberOfTickets} Ticket`;
    else buttonText = `Buy ${numberOfTickets} Ticket`;
  }
  if (panel === 'buyTickets') {
    buttonText = 'Buy Now';
  }

  let loadingText = '';
  if (enterRace.isLoading) {
    loadingText = 'Entering Race...';
  }
  
  if (
    panel === 'selectTyres' &&
    (setCarPart.isLoading || tuningForRace.isLoading || isLoadingTuning)
  ) {
    loadingText = 'Saving Setup...';
  }

  return (
    <RDrawer
      drawerBodyProps={{
        p: liveRacePanel ? 0 : 'auto',
        py: liveRacePanel ? 0 : 4,
      }}
      drawerHeaderProps={{ bg: racePreviewGradient }}
      drawerFooterProps={{ bg: racePreviewGradient, borderTop: 'none' }}
      heading={
        <Flex
          w={{ base: '19rem', md: '36rem' }}
          alignItems={{ base: 'left', md: 'center' }}
          justifyContent="space-between"
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Flex alignItems="center">
            <RTiers tier={race?.tier?.name as RaceTier} tierType="driver" mr="2" />
            {race?.track?.frontendName || (
              <Skeleton height="1rem" width="7rem" />
            )}
          </Flex>
          {race?.startTime && raceStatus && (
            <Flex
              alignItems="center"
              textTransform="none"
              fontFamily="body"
              mt={[2, 0]}
            >
              <RRaceStatus status={raceStatus} />
              <RDateWithLabel
                ml="0.5rem"
                w="full"
                fontSize="1rem"
                time={race?.startTime as string}
                status={raceStatus}
              />
            </Flex>
          )}
        </Flex>
      }
      headerContent={
        !isLoadingRaceById &&
        panel !== 'raceDetails' && (
          <RRaceInfo
            race={race}
            hasDetails={
              panel === 'selectTyres' ||
              liveRacePanel ||
              race?.status === 'ended'
            }
          />
        )
      }
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setCanWatchRace(false);
        setPanel('raceDetails');
      }}
      drawerBody={
        <Flex flexDirection="column" width="full">
          {drawerPanels[panel]}
        </Flex>
      }
      drawerFooter={
        panel === 'buyTickets' ? (
          <Flex width="full" alignItems="flex-start" flexDir="column">
            <Button
              variant="secondary-outlined"
              textAlign="center"
              onClick={prevPanel}
            >
              <IconArrow />
            </Button>
          </Flex>
        ) : (
          <>
            {isLoadingRaceById ||
              (isSuccessRaceById && panel === 'admissionUse' && (
                <Flex
                  gap={4}
                  mb={{ base: 0, md: '4' }}
                  width="full"
                  alignItems="center"
                  flexDir="column"
                >
                  {isLoadingRaceById && (
                    <>
                      <Skeleton width="16rem" />
                      <Skeleton width="100%" />
                    </>
                  )}

                  {isSuccessRaceById && panel === 'admissionUse' && (
                    <Flex flexDir="row">
                      <Heading
                        fontWeight="normal"
                        as="h4"
                        size="xs"
                        textTransform={
                          panel === 'admissionUse' ? 'uppercase' : undefined
                        }
                        color={
                          ticketsAvailable >= numberOfTickets
                            ? 'white.80'
                            : 'bloodMoon.100'
                        }
                      >
                        {panel === 'admissionUse'
                          ? `${ticketsAvailable} Tickets Available`
                          : 'By paying you accept Racino’s terms'}
                      </Heading>
                    </Flex>
                  )}
                </Flex>
              ))}

            {isSuccessRaceById && (isLive || canWatchRace) && (
              <Show above="lg">
                <ButtonGroup w="full">
                  <Button
                    isDisabled={!race?.track.has2D || isMobileDevice()}
                    width="full"
                    variant="secondary-outlined"
                    as="a"
                    cursor="pointer"
                    id="2d-button"
                    onClick={(e) => {
                      if (!race?.track.has2D) {
                        e.preventDefault();
                      } else {
                        window.open(url('2D'), '_blank');
                      }
                    }}
                  >
                    Watch in 2D
                  </Button>

                  <Button
                    isDisabled={!race?.track.has3D || isMobileDevice()}
                    width="full"
                    variant="secondary-outlined"
                    as="a"
                    cursor="pointer"
                    id="3d-button"
                    onClick={(e) => {
                      if (!race?.track.has3D) {
                        e.preventDefault();
                      } else {
                        window.open(url('3D'), '_blank');
                      }
                    }}
                  >
                    Watch in 3D
                  </Button>
                </ButtonGroup>

                {isMobileDevice() && (
                  <Text fontSize="0.75rem" color="bloodMoon.100" mt="0.25rem">
                    Mobile viewing coming soon
                  </Text>
                )}
              </Show>
            )}

            {panel === 'selectTyres' && (
              <OverallTuning tuning={tuningValues} selectedCar={selectedCar} />
            )}
            {isSuccessRaceById && (canEnterRace || canTune) && (
              <ButtonGroup w="full">
                {panel !== 'raceDetails' && (
                  <Button
                    variant="secondary-outlined"
                    textAlign="center"
                    id="previous-button"
                    onClick={prevPanel}
                  >
                    <IconArrow />
                  </Button>
                )}

                <NextButton
                  isLoading={Boolean(loadingText)}
                  loadingText={loadingText}
                  id="bottom-drawer-button"
                  isDisabled={
                    (panel === 'raceDetails' && isLoadingGarage) ||
                    (panel === 'selectCar' && selectedCar?.locked)
                  }
                  variant={panel === 'admissionUse' ? 'tertiary' : 'primary'}
                  onClick={async () => {
                    if (isEnteringRace) {
                      // clear any existing errors
                      setRaceAlert(undefined);

                      const res = await enterRace.mutateAsync({
                        enterRaceInput: {
                          carId: selectedCarId,
                          raceId,
                        },
                      });

                      await queryClient.invalidateQueries({
                        queryKey: ['raceByIdQuery', raceId],
                      });

                      if (res.enterRace.status === 'accepted') {
                        showAnimatedTransition({
                          Icon: IconCheckeredFlag,
                          title: 'race',
                          id: 'race-entered-success',
                          subtitle: 'entered',
                          color: 'bloodMoon.100',
                          bgColor:
                            'linear-gradient(81.73deg, #008A8A -122.13%, #000000 46.69%, #000000 57.14%, #D83832 212.04%)',
                          gradientColor: {
                            from: 'rgba(216, 56, 50, 0.2)',
                            to: 'rgba(216, 56, 50, 0)',
                          },
                        });
                        nextPanel();
                        setUserInRace(true);
                      }

                      queryClient.refetchQueries({
                        queryKey: ['nextUpRacesByTierAndStatusQuery'],
                      });
                    }

                    if (panel === 'selectTyres') {
                      onClose();
                      toastIdRef.current = toast({
                        position: 'bottom-right',
                        render: () => (
                          <RToast
                            variant="success"
                            title={`Successfully qualifying for ${race?.track?.frontendName}`}
                            onClose={() =>
                              toast.close(toastIdRef.current as ToastId)
                            }
                          />
                        ),
                      });
                      setPanel('raceDetails');
                    }

                    // if (
                    //   panel === 'selectCar' &&
                    //   selectedCar?.tier?.name !== race?.tier?.name
                    // ) {
                    //   return setRaceAlert(
                    //     <RAlert
                    //       variant="error"
                    //       description="Cannot join this race: car rank is insufficient"
                    //     />
                    //   );
                    // }
                    if (panel === 'admissionUse' && !isEnteringRace) {
                      onOpenAdmissionUse();
                      return;
                    }

                    if (!isEnteringRace && panel !== 'selectTyres') {
                      nextPanel();
                    }
                  }}
                >
                  {buttonText}
                </NextButton>
              </ButtonGroup>
            )}
          </>
        )
      }
    />
  );
};

export default RacePreviewDrawer;
