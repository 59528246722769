import {
  Text,
  Flex,
  Box,
  BoxProps,
  useDisclosure,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import { IconInfo } from 'icons';
import {
  RCurrency,
  RModal,
  RTooltip,
  RPositionAndPoints,
  RLoadingAnimation,
} from 'components';
import { useGetPrizePool } from 'hooks';
import { CurrencyType } from 'api/generated/graphql';

export const PrizePoolStrategy = ({
  raceId,
  currency = CurrencyType.Usd,
}: {
  raceId: string;
  currency?: CurrencyType;
}) => {
  const { data: prizePoolData, isLoading: isLoadingPrizePool } =
    useGetPrizePool({ raceId });
  const distributionStrategyData =
    prizePoolData?.getPrizePool.prizePoolDistributions?.sort(
      (a, b) => a.gridPosition - b.gridPosition
    ) || [];

  return (
    <>
      <Box padding="6">
        <Text mb="12">
          Points and prizes are listed below for each position. The displayed
          prize pool shows the minimum for 2+ participants and scales with more
          entries. Races with fewer than 2 participants are canceled and tickets
          refunded if applicable.
        </Text>
        {isLoadingPrizePool && (
          <Flex justifyContent="center">
            <RLoadingAnimation />
          </Flex>
        )}
        {!isLoadingPrizePool &&
          distributionStrategyData &&
          distributionStrategyData.length === 0 && (
            <Text textAlign="center">
              Prize pool information will update when participants enter this
              race.
            </Text>
          )}
        {!isLoadingPrizePool && distributionStrategyData && (
          <UnorderedList mx="-2rem" __css={{ columnCount: 2 }}>
            {[...distributionStrategyData].map(
              ({ gridPosition, points, earnings }) => {
                return (
                  <ListItem
                    display="flex"
                    alignItems="center"
                    mb="4"
                    mx="1rem"
                    key={gridPosition}
                  >
                    <Text
                      width="1.5rem"
                      height="1.5rem"
                      lineHeight="1.5rem"
                      bg={gridPosition <= 3 ? 'white.100' : 'white.20'}
                      color={gridPosition <= 3 ? 'black.80' : 'white.80'}
                      textAlign="center"
                      fontWeight="bold"
                      fontSize="md"
                      mr="4"
                    >
                      {gridPosition}
                    </Text>

                    <RCurrency
                      amount={earnings}
                      currency={currency}
                      size="md"
                    />

                    <RPositionAndPoints
                      points={points}
                      ml="auto"
                      variant="white"
                    />
                  </ListItem>
                );
              }
            )}
          </UnorderedList>
        )}
      </Box>
    </>
  );
};

const PrizePool = ({
  raceId,
  poolAmount = 0,
  text = 'Prize Pool',
  size = 'md',
  currency = CurrencyType.Usd,
  ...rest
}: BoxProps & {
  raceId: string;
  poolAmount: number | undefined;
  text?: string;
  currency?: CurrencyType;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <RTooltip label="View details">
        <Flex
          cursor="pointer"
          flexDir="column"
          gap={0}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
          alignItems="flex-end"
          {...rest}
        >
          <Flex flexDir="row" alignItems="center">
            <Text
              fontFamily="heading"
              color="white.80"
              as="h4"
              size={size === 'xl' ? 'lg' : size}
              textTransform="uppercase"
            >
              {text}
            </Text>
            <IconInfo ml="2" />
          </Flex>
          <RCurrency
            size={size}
            amount={poolAmount}
            currency={currency}
            justifyContent="flex-end"
          />
        </Flex>
      </RTooltip>
      <RModal
        size="3xl"
        title="Prize Pool Placing"
        isOpen={isOpen}
        onClose={onClose}
        variant="darkGrey"
        isCentered
      >
        <PrizePoolStrategy raceId={raceId} currency={currency} {...rest} />
      </RModal>
    </>
  );
};

export default PrizePool;
