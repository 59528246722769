import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTiersRarity = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={30}
    fill="none"
    viewBox="0 0 26 30"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M13.343 1 1 10.917M13.343 1 25 10.917M13.343 1v14.467M1 10.917 13.343 29 25 10.917m-24 0 12.343 4.55M25 10.917l-11.657 4.55"
    />
  </svg>
));
export default SvgIconTiersRarity;
