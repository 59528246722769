/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  Text,
  Heading,
  ButtonGroup,
  Box,
  Grid,
  GridItem,
  Alert,
  useDisclosure,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  ReactElement,
  useState,
} from 'react';

import {
  RAlert,
  RCarCard,
  RLoadingAnimation,
  RPartCard,
  RPartInfoDrawer,
  RToast,
} from 'components';
import {
  IconCheckGreen,
  IconChevron,
  IconEmptySet,
  IconPartBrakeCooling,
  IconPartEngineCooling,
  IconPartFrontWing,
  IconPartRearWing,
  IconPartTransmissionRatios,
  IconPlusCircle,
  IconTrashCan,
} from 'icons';
import pickCarParts from 'utils/pickCarParts';
import { Cars, SavedSetups } from 'api/generated/graphql';
import {
  AppliedLivery,
  CarPart,
  CarTier,
  Livery,
  PartGroup,
  PartNames,
  PartTypes,
  RarityTier,
} from 'types';
import { setAppliedParts } from 'utils/partData';
import { useGetCarParts, useGetSavedSetups, useRemoveSavedSetups, useSetCarPart } from 'hooks';
import { camelCase } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import SavedSetupModal from 'components/SavedSetups/SavedSetupModal';
import SavedSetupDeleteModal from 'components/SavedSetups/SavedSetupDeleteModal';

const suggestedPartsBasedOnTrack = {
  "Bahrain": ["FW1A", "FW1B", "RW40", "RW50", "BODYWORK3", "BODYWORK4", "BODYWORK5", "DUCT3", "DUCT4", "DUCT5", "MEDIUM SPEED", "HIGH SPEED"],
  "Saudi Arabia": ["RW30", "RW40", "FW1B", "BODYWORK3", "BODYWORK4", "BODYWORK5", "DUCT2", "DUCT3", "MEDIUM SPEED", "LOW SPEED"],
  "Australia": ["RW50", "RW40", "FW1B", "FW1C", "BODYWORK2", "BODYWORK3", "DUCT2", "DUCT3", "MEDIUM SPEED", "HIGH SPEED"],
  "Azerbaijan": ["RW30", "FW1A", "BODYWORK3", "BODYWORK4", "DUCT4", "DUCT3", "MEDIUM SPEED", "HIGH SPEED"],
  "Miami": ["RW30", "RW40", "FW1B", "FW1C", "BODYWORK3", "BODYWORK4", "BODYWORK5", "DUCT4", "DUCT3", "MEDIUM SPEED", "HIGH SPEED"],
  "Monaco": ["RW50", "FW1B", "BODYWORK2", "BODYWORK3", "BODYWORK4", "DUCT4", "DUCT3", "LOW SPEED"],
  "Spain": ["RW50", "FW1B", "FW1C", "BODYWORK1", "BODYWORK2", "BODYWORK3", "DUCT1", "DUCT2", "MEDIUM SPEED", "LOW SPEED"],
  "Canada": ["RW30", "RW40", "FW1A", "BODYWORK3", "BODYWORK4", "DUCT4", "DUCT3", "MEDIUM SPEED", "HIGH SPEED"],
  "Austria": ["RW40", "RW50", "FW1A", "FW1B", "BODYWORK1", "BODYWORK2", "DUCT4", "DUCT3", "VERY LOW SPEED", "LOW SPEED"],
  "United Kingdom": ["RW40", "RW50", "FW1C", "BODYWORK1", "BODYWORK2", "DUCT1", "DUCT2", "HIGH SPEED", "VERY HIGH SPEED"],
  "Hungary": ["RW50", "FW1B", "FW1C", "BODYWORK2", "BODYWORK3", "BODYWORK4", "DUCT2", "DUCT3", "VERY LOW SPEED", "LOW SPEED"],
  "Belgium": ["RW20", "RW30", "FW1B", "FW1C", "BODYWORK1", "BODYWORK2", "DUCT2", "DUCT3", "MEDIUM SPEED", "HIGH SPEED"],
  "Netherlands": ["RW50", "FW1C", "BODYWORK1", "BODYWORK2", "DUCT2", "DUCT3", "MEDIUM SPEED", "LOW SPEED"],
  "Italy": ["RW10", "RW20", "FW1A", "FW1B", "BODYWORK2", "BODYWORK3", "DUCT4", "DUCT5", "VERY HIGH SPEED", "HIGH SPEED"],
  "Singapore": ["RW50", "FW1A", "FW1B", "BODYWORK3", "BODYWORK4", "BODYWORK5", "DUCT2", "DUCT3", "VERY LOW SPEED", "LOW SPEED"],
  "Japan": ["RW40", "FW1C", "BODYWORK3", "BODYWORK2", "DUCT1", "DUCT2", "MEDIUM SPEED", "HIGH SPEED"],
  "Qatar": ["RW50", "FW1B", "FW1C", "BODYWORK5", "BODYWORK3", "BODYWORK4", "DUCT2", "DUCT3", "MEDIUM SPEED", "LOW SPEED"],
  "United States": ["RW40", "RW50", "FW1B", "FW1C", "BODYWORK3", "BODYWORK4", "DUCT2", "DUCT3", "MEDIUM SPEED", "LOW SPEED"],
  "Mexico": ["RW50", "FW1A", "FW1B", "BODYWORK5", "BODYWORK4", "DUCT4", "DUCT3", "VERY HIGH SPEED", "HIGH SPEED"],
  "Brazil": ["RW30", "RW40", "FW1C", "FW1B", "BODYWORK3", "BODYWORK4", "DUCT4", "DUCT3", "MEDIUM SPEED", "LOW SPEED"],
  "Abu Dhabi": ["RW40", "RW50", "FW1C", "FW1B", "BODYWORK3", "BODYWORK4", "DUCT2", "DUCT3", "MEDIUM SPEED", "HIGH SPEED"],
} as Record<string, string[]>;

type RaceName = keyof typeof suggestedPartsBasedOnTrack;


const SelectCar = ({
  cars,
  setSelectedCarId,
  raceAlert,
  raceName,
}: {
  cars: Cars[] | undefined;
  setSelectedCarId: Dispatch<SetStateAction<string | undefined>>;
  enterRaceIsSuccess?: boolean;
  raceAlert: ReactElement<typeof Alert> | undefined;
  raceName?: string;
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const [selectedCarIndex, setSelectedCarIndex] = useState(0);
  const [activePartCategoryName, setActivePartCategoryName] =
    useState<PartNames>('Front Wing');
  const [selectedPart, setSelectedPart] = useState<CarPart | null>();
  const [selectedSavedSetup, setSelectedSavedSetup] = useState<any | null>(null);
  const [isApplyingSavedSetup, setIsApplyingSavedSetup] = useState(false);

  const {
    isOpen: isOpenPartInfoDrawer,
    onOpen: onOpenPartInfoDrawer,
    onClose: onClosePartInfoDrawer,
  } = useDisclosure();

  const {
    isOpen: isOpenSavedSetupNameModal,
    onOpen: onOpenSavedSetupNameModal,
    onClose: onCloseSavedSetupNameModal,
  } = useDisclosure();

  const {
    isOpen: isOpenSavedSetupDeleteModal,
    onOpen: onOpenSavedSetupDeleteModal,
    onClose: onCloseSavedSetupDeleteModal,
  } = useDisclosure();

  const selectedCar = cars?.[selectedCarIndex];
  const { data: partsData, isLoading: isLoadingPartsData } = useGetCarParts(
    selectedCar?.id || ''
  );

  const { data: savedSetupsData, isLoading: isLoadingSavedSetupsData } = useGetSavedSetups({ carId: selectedCar?.id || '' });
  const { mutateAsync: setCarPart, isLoading: isLoadingSetCarPart } = useSetCarPart();

  const filteredSavedSetups = savedSetupsData?.getSavedSetups.filter(setup => {
    const conditions = [
      setup.frontWingTuning === null,
      setup.rearWingTuning === null,
    ];

    return conditions.every(condition => condition);
  });

  const parts = pickCarParts(selectedCar as Cars);
  const appliedPartIds = parts.map((p) => p.id);
  const carouselData: PartGroup[] =
    partsData?.getCarParts.map((partGroup) => {
      return {
        id: partGroup.id,
        partGroupName: partGroup.name,
        partsInGroup: partGroup.carParts.map((part) => {
          return {
            partType: camelCase(partGroup.name) as PartTypes,
            ...part,
          };
        }),
      };
    }) || [];

  useEffect(() => {
    if (selectedCar) {
      setSelectedCarId(selectedCar.id);
    }
  }, [selectedCar, setSelectedCarId]);

  const handleChangeCarPrev = () => {
    if (selectedCarIndex > 0) {
      setSelectedCarIndex(selectedCarIndex - 1);
    }
  };

  const handleChangeCarNext = () => {
    if (selectedCarIndex < (cars?.length || 0) - 1) {
      setSelectedCarIndex(selectedCarIndex + 1);
    }
  };

  const appliedParts = setAppliedParts({ carouselData, appliedPartIds });

  const activePartGroup = appliedParts.find(
    (group) => group.partGroupName === activePartCategoryName
  );

  const rankedParts = activePartGroup?.partsInGroup
    .sort((a, b) => {
      if (a?.rankRequired && b?.rankRequired) {
        return a.rankRequired - b.rankRequired;
      }

      return 0;
    })
    .filter((part) => !part.locked);


  const handleApplySavedSetup = async (savedSetup: any) => {
    const setupParts = {
      frontWing: savedSetup?.frontWingId,
      rearWing: savedSetup?.rearWingId,
      brakeCooling: savedSetup?.brakeCoolingId,
      engineCooling: savedSetup?.engineCoolingId,
      transmissionRatio: savedSetup?.transmissionId,
    };

    const filteredSetupParts = Object.fromEntries(
      Object.entries(setupParts).filter(([_, value]) => value !== undefined)
    );

    try {
      await setCarPart({ carId: selectedCar?.id || '', carParts: filteredSetupParts });
      queryClient.invalidateQueries({
        queryKey: ['garageQuery'],
      });
      toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="success"
            title={`Saved Setup successfully applied`}
          />
        ),
      });
    } catch (error) {
      console.error("Error applying saved setup:", error);
      toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title={`Error applying saved setup`}
          />
        ),
      });
    }
  }

  const handleSelectSavedSetup = async (savedSetup: any) => {
    setSelectedSavedSetup(savedSetup);
    try {
      await handleApplySavedSetup(savedSetup);
      toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="success"
            title={`Setup successfully saved`}
          />
        ),
      });
    } catch (error) {
      console.error("Error applying saved setup:", error);
    }
  }

  useEffect(() => {
    if (!isOpenPartInfoDrawer)
      queryClient.invalidateQueries({
        queryKey: ['partsQuery'],
      });
  }, [isOpenPartInfoDrawer]);

  useEffect(() => {
    if (!isLoadingPartsData && !partsData)
      queryClient.invalidateQueries({
        queryKey: ['partsQuery'],
      });
  }, [partsData, isLoadingPartsData]);

  useEffect(() => {
    console.log(isApplyingSavedSetup)
  }, [isApplyingSavedSetup])

  return (
    <>
      <Box height="fit-content" pb="20">
        <Flex w="full" mb="3">
          <Tooltip label="Select the Car & Parts you want to use" openDelay={500}>
            <Heading as="h1" size="lg" textTransform="uppercase" mr="6">
              Select Car
            </Heading>
          </Tooltip>

          <ButtonGroup ml="auto" gap={3}>
            <Button
              variant="secondary-outlined"
              width="12"
              isDisabled={selectedCarIndex === 0}
              onClick={handleChangeCarPrev}
              id="previous-car-button"
            >
              <IconChevron transform="rotate(180deg)" />
            </Button>

            <Button
              variant="secondary-outlined"
              width="12"
              isDisabled={selectedCarIndex === (cars?.length || 0) - 1}
              onClick={handleChangeCarNext}
              id="next-car-button"
            >
              <IconChevron />
            </Button>
          </ButtonGroup>
        </Flex>

        <Text mb="6">Select the right car for the right conditions.</Text>

        {selectedCar && (
          <RCarCard
            showRepairButton
            carParts={parts}
            carId={selectedCar?.id}
            hasInsetBorder={false}
            alert={raceAlert}
            locked={selectedCar?.locked}
            vehicleName={selectedCar?.name || ''}
            livery={(selectedCar?.livery || '') as Livery}
            appliedLivery={(selectedCar?.appliedLivery || '') as AppliedLivery}
            backgroundPreload
            isTrialCar={selectedCar?.tier?.rank === 0}
            tier={selectedCar?.tier?.name as CarTier}
            rarity={selectedCar?.rarity as RarityTier}
            timesUsed={selectedCar?.timesUsed}
            maximumTimesUsed={selectedCar?.maximumTimesUsed}
            nextRepairAt={selectedCar?.nextRepairAt}
            usageSinceLastRepair={selectedCar?.usageSinceLastRepair}
            mb="6"
            imageProps={{
              paddingY: 8,
              transform: 'scale(0.85)'
            }}
            showCarHealth
          />
        )}

       <Flex
        justify="space-between"
        align="center"
        mb="6"
       >
        <Heading as="h2" size="md" textTransform="uppercase">
          Parts Setup
        </Heading>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={
              <IconChevron __css={{ transform: 'rotate(90deg)' }} ml={4} />
            }
            variant="secondary"
            textTransform="none"
            h="34px"
            fontSize="14px"
          >
            {selectedSavedSetup ? selectedSavedSetup.name : 'Saved Part Setups'}
          </MenuButton>
          <MenuList
            w="full"
            bg="darkVoid.100"
            p="4"
            zIndex={10}
          >
            <MenuItem
              bg="darkVoid.100"
            >
              <Flex
                flexDir="column"
                gap="2"
              >
                <Flex
                  align="center"
                  gap="2"
                  justify="space-between"
                  color="white.80"
                  w="full"
                  minW="35rem"
                  onClick={onOpenSavedSetupNameModal}
                >
                  <Flex
                    align="center"
                    gap="4"
                  >
                    <IconPlusCircle 
                      __css={{
                        path: { fill: 'white.80' },
                      }}
                    />
                    <Text
                      fontFamily="heading"
                    >
                      New Saved Setup
                    </Text>
                  </Flex>
                  <IconChevron />
                </Flex>
                <Flex
                  mt="1rem"
                  w="full"
                  maxW="30rem"
                  margin="auto"
                >
                  <Text
                    fontSize="14px"
                    color="white.60"
                    fontFamily="body"
                  >
                    New Saved Setups only apply to the currently selected car and parts within Parts Setup
                  </Text> 
                </Flex>
              </Flex>
            </MenuItem>
            <MenuDivider />
            {isLoadingSavedSetupsData ? (
              <MenuItem bg="darkVoid.100">
                <Flex align="center" justify="center" w="full">
                  <Spinner color="white.80" size="md" />
                </Flex>
              </MenuItem>
            ) : filteredSavedSetups?.length === 0 ? (
              <MenuItem
                bg="darkVoid.100"
              >
                <Flex
                  align="center"
                  gap="4"
                  color="white.80"
                >
                  <IconEmptySet
                    width="20px"
                    height="20px"
                    __css={{
                      path: { fill: 'white.80' },
                    }}
                  />
                  <Text
                    fontFamily="heading"
                  >
                    No Saved Setups
                  </Text>
                </Flex>
              </MenuItem>
            ) : (
              filteredSavedSetups?.map((savedSetup) => (
                <MenuItem
                  bg="darkVoid.100"
                >
                  <Flex
                    align="center"
                    justify="space-between"
                  >
                    <Flex
                      align="center"
                      gap="2"
                      color="white.80"
                      w="full"
                      minW="34rem"
                      onClick={() => handleSelectSavedSetup(savedSetup)}
                    >
                      <Flex
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedSavedSetup(savedSetup);
                          onOpenSavedSetupDeleteModal();
                        }}
                      >
                        <IconTrashCan
                          __css={{
                            path: { fill: 'white.80' },
                          }}
                        />
                      </Flex>
                      <Text
                        fontFamily="heading"
                      >
                        {savedSetup.name}
                      </Text>
                    </Flex>
                    {selectedSavedSetup?.id === savedSetup.id && (
                      <IconCheckGreen />
                    )}
                  </Flex>
                </MenuItem>
              ))
            )}  
          </MenuList>
        </Menu>
       </Flex>

        <Grid templateColumns="repeat(5, 1fr)" gap={3} w="full" mb="1rem">
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Front Wing'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Front Wing')}
              id="front-wing-button"
            >
              <IconPartFrontWing h="1rem" w="auto" />
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Rear Wing'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Rear Wing')}
              id="rear-wing-button"
            >
              <IconPartRearWing h="1rem" w="auto" />
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Engine Cooling'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Engine Cooling')}
              id="engine-cooling-button"
            >
              <IconPartEngineCooling h="1rem" w="auto" />
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Brake Cooling'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Brake Cooling')}
              id="brake-cooling-button"
            >
              <IconPartBrakeCooling h="1rem" w="auto" />
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Transmission Ratios'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Transmission Ratios')}
              id="transmission-ratios-button"
            >
              <IconPartTransmissionRatios h="1rem" w="auto" />
            </Button>
          </GridItem>
        </Grid>

        <Heading as="h2" size="0.875rem" textTransform="uppercase" mb="1rem">
          {activePartCategoryName}
        </Heading>
        {isLoadingSetCarPart && (
          <Flex
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="9999"
            bg="rgba(0, 0, 0, 0.5)"
            alignItems="center"
            justifyContent="center"
          >
            <RLoadingAnimation />
          </Flex>
        )}

        {!isLoadingPartsData && partsData ? (
          <Grid templateColumns="repeat(1, 1fr)" gap={3}>
            {rankedParts?.length === 0 ? (
              <RAlert
                variant="error"
                description="This car doesn't have parts in this category."
              />
            ) : (
              rankedParts?.map((part, i) => {
                if (part.locked) return null;

                return (
                  <GridItem key={part.id}>
                    <RPartCard
                      isSuggested={suggestedPartsBasedOnTrack[raceName as RaceName]?.includes(part.id)}
                      id={`${part.id}-part-card`}
                      carPart={part}
                      cursor="pointer"
                      showPartRaceInfo
                      onClick={() => {
                        onOpenPartInfoDrawer();
                        setSelectedPart(part);
                      }}
                    />
                  </GridItem>
                );
              })
            )}
          </Grid>
        ) : (
          <Flex w="full" alignItems="center" justifyContent="center">
            <RLoadingAnimation />
          </Flex>
        )}
      </Box>

      <RPartInfoDrawer
        isOpen={isOpenPartInfoDrawer}
        onClose={onClosePartInfoDrawer}
        isPartApplied={appliedPartIds.includes(selectedPart?.id || '')}
        carPart={selectedPart as CarPart}
        carId={selectedCar?.id || ''}
      />
      <SavedSetupModal
        isOpen={isOpenSavedSetupNameModal}
        onClose={onCloseSavedSetupNameModal}
        selectedCar={selectedCar}
      />
      <SavedSetupDeleteModal
        isOpen={isOpenSavedSetupDeleteModal}
        onClose={onCloseSavedSetupDeleteModal}
        savedSetupId={selectedSavedSetup?.id || ''}
        carId={selectedCar?.id || ''}
        savedSetupName={selectedSavedSetup?.name || ''}
        setSelectedSavedSetup={setSelectedSavedSetup}
      />
    </>
  );
};

export default SelectCar;
