import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTemperature = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={20}
    fill="none"
    viewBox="0 0 13 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2C2C2E"
      d="M7.125 12.617c.703.274 1.25.977 1.25 1.797 0 1.016-.86 1.875-1.875 1.875a1.875 1.875 0 0 1-1.875-1.875c0-.82.508-1.523 1.25-1.797v-1.953c0-.351.273-.625.625-.625.313 0 .625.274.625.625v1.953Zm3.75-1.719c.742.977 1.25 2.149 1.25 3.477A5.626 5.626 0 0 1 6.5 20a5.602 5.602 0 0 1-5.625-5.625c0-1.328.469-2.5 1.25-3.477V4.375C2.125 1.992 4.117 0 6.5 0s4.375 1.992 4.375 4.375v6.523ZM6.5 17.5c1.719 0 3.125-1.367 3.125-3.086 0-.976-.508-1.914-1.25-2.46v-7.54c0-1.016-.86-1.875-1.875-1.875-1.055 0-1.875.86-1.875 1.875v7.54a3.071 3.071 0 0 0-1.25 2.46c0 1.719 1.367 3.086 3.125 3.086Z"
    />
  </svg>
));
export default SvgIconTemperature;
