import { Flex, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { PartTypeName } from "api/generated/graphql";
import RepairPartsDrawer from "components/RepairPartsDrawer/RepairPartsDrawer";
import { Icon2Cars, IconCar, IconCheckeredFlag, IconWrenchScrewdriver } from "icons";
import { CarPart } from "types";

const RepairCounter = ({
  timesUsed,
  maximumTimesUsed,
  isGarage,
  carId,
  requiredRepairPart
}: {
  timesUsed: number | null,
  maximumTimesUsed: number | null,
  isGarage?: boolean,
  carId: string | undefined,
  requiredRepairPart?: PartTypeName
}) => {
  const percentageUsed = maximumTimesUsed && timesUsed ? (timesUsed / maximumTimesUsed) * 100 : 0;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenTooltip, onOpen: onOpenTooltip, onClose: onCloseTooltip } = useDisclosure();

  return (
    <Tooltip label="Races Left Until Repair" openDelay={500} isOpen={isOpenTooltip}>
      <Flex
        justifyContent={{ base: 'center', md: 'space-between' }}
        minW={isGarage ? '6rem' : { base: "10rem", md: "15rem" }}
        ml={isGarage ? 0 : { base: 0, md: 'auto' }}
        alignItems="center"
        onClick={(e) => {
          e.stopPropagation()
          onOpen()
        }}
        onMouseEnter={onOpenTooltip}
        onMouseLeave={onCloseTooltip}
        px={{ base: '1', md: '3' }}
        py={{ base: '1', md: '2' }}
        position="relative"
        bg="rgba(0, 0, 0, 0.2);"
        cursor={'pointer'}
        backgroundImage={`linear-gradient(to right, ${timesUsed === maximumTimesUsed ? isOpenTooltip ? "bloodMoon.100" : "rooibosTea.100" : 'rooibosTea.100'} ${percentageUsed}%, rgba(0, 0, 0, 0.2) ${percentageUsed}%)`}
        _hover={{
          backgroundImage: `linear-gradient(to right, ${timesUsed === maximumTimesUsed ? "bloodMoon.100" : 'fieryCoral.100'} ${percentageUsed}%, rgba(0, 0, 0, 0.2) ${percentageUsed}%)`,
          transition: 'all 0.3s ease',
          cursor: timesUsed === maximumTimesUsed ? 'pointer' : 'not-allowed',
        }}
      >
        {!isGarage && (
          <Text
            fontFamily={'heading'}
            fontSize={{ base: "xs", md: "md" }}
            color="white.100"
            zIndex={1} // Keep text above the background
          >
            REPAIR
          </Text>
        )}
        <Flex
          alignItems="center"
          gap={{ base: '1', md: '2' }}
          zIndex={1} // Keep text above the background
        >
          <Text
            fontFamily={'body'}
            fontSize={{ base: 'md', md: 'lg' }}
            color="white.80"
          >
            {timesUsed}/{maximumTimesUsed}
          </Text>
          {timesUsed === maximumTimesUsed ? (
            <IconWrenchScrewdriver w={{ base: '1rem', md: '1.25rem' }}
            />
          ) : (
            <IconCar w={{ base: '1rem', md: '1.25rem' }} sx={{
              '& path': {
                stroke: 'white.80',
                fill: 'white.80'
              },
            }}
            />
          )}
        </Flex>

        <RepairPartsDrawer
          carId={carId}
          isOpen={isOpen}
          onClose={onClose}
          maximumTimesUsed={maximumTimesUsed}
          requiredRepairPart={requiredRepairPart}
        />
      </Flex>
    </Tooltip>
  );
};

export default RepairCounter;