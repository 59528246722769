import { Text, Flex, FlexProps, ChakraProps } from '@chakra-ui/react';
import { CurrencyType } from 'api/generated/graphql';

import { IconDollar, IconVext } from 'icons';

type Props = {
  size: ChakraProps['fontSize'];
  amount: number | string;
  hasCurrencySymbol?: boolean;
  hasCurrencyCode?: boolean;
  currency?: CurrencyType;
  fontWeight?: ChakraProps['fontWeight'];
};

const Currency = ({
  amount = 0,
  size = 'md',
  hasCurrencyCode = true,
  hasCurrencySymbol = true,
  currency = CurrencyType.Usd,
  fontWeight = 'bold',
  ...rest
}: FlexProps & Props) => {
  return (
    <Flex
      className="Currency"
      flexDir="row"
      fontSize={size === 'xl' ? '3.125rem' : size === 'lg' ? '1.25rem' : size}
      alignItems="center"
      {...rest}
    >
      {hasCurrencySymbol && (
        <>
          {currency === CurrencyType.Vext ? (
            <IconVext
              mr="1.5"
              w="auto"
              h="1.125em"
              __css={{
                path: {
                  fill: 'vext.100',
                },
              }}
            />
          ) : (
            <IconDollar
              mr="1.5"
              w="auto"
              h="0.9em"
              __css={{
                path: {
                  fill: 'treasuredTeal.100',
                },
              }}
            />
          )}
        </>
      )}

      <Text
        color={
          currency === CurrencyType.Vext ? 'vext.100' : 'treasuredTeal.100'
        }
        fontSize="inherit"
        fontWeight={fontWeight}
      >
        {typeof amount === 'number' ? (amount / 100).toFixed(2) : amount}
        {hasCurrencyCode && (
          <span
            style={{
              fontSize: '50%',
              display: 'inline-block',
              marginLeft: '0.25rem',
              fontWeight: 'normal',
            }}
          >
            {currency}
          </span>
        )}
      </Text>
    </Flex>
  );
};

export default Currency;
