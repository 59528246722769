import { Flex, GridItem, Heading } from "@chakra-ui/react";
import { RBaseCard, RBaseCardGradient, RImage, RTooltip } from "components";
import PartInfo from "components/PartInfo/PartInfo";
import { IconCornerCheckmarkLight, IconTyreC1, IconTyreC2, IconTyreC3, IconTyreC4, IconTyreC5, IconTyreInter, IconTyreWet } from "icons";
import { CarPart, Tyres } from "types";
import { partsData } from 'utils/partData';

type PerformancePartCardProps = {
  carPart: CarPart;
  isFirstCar?: boolean;
}

const RPerformancePartCard = ({
  carPart,
  isFirstCar
}: PerformancePartCardProps) => {
  let partMetadata = partsData.find((p) => p.name === carPart?.partType);

  if (carPart?.partType === 'tyres') {
    partMetadata = {
      icon: IconTyreC1,
      name: 'tyres',
      tyreId: carPart.id,
      screenName: `${carPart.id} - ${carPart.name}`,
    };
  }

  const tyreIcon = {
    C1: <IconTyreC1 w="4rem" h="4rem" />,
    C2: <IconTyreC2 w="4rem" h="4rem" />,
    C3: <IconTyreC3 w="4rem" h="4rem" />,
    C4: <IconTyreC4 w="4rem" h="4rem" />,
    C5: <IconTyreC5 w="4rem" h="4rem" />,
    INTER: <IconTyreInter w="4rem" h="4rem" />,
    WET: <IconTyreWet w="4rem" h="4rem" />,
  };

  const partInfos: any = {
    "frontWing": { title1: "HANDLING" },
    "rearWing": { title1: "GRIP", title2: "SPEED" },
    "engineCooling": { title1: "COOLING", title2: "GRIP" },
    "brakeCooling": { title1: "BRAKING", title2: "GRIP" },
    "transmissionRatios": { title1: "SPEED", title2: "ACCELL" },
    "tyres": { title1: "GRIP" }
  }

  const getStatus = (partType: string, highSetting: number) => {
    switch (partType) {
      case "frontWing":
        if (highSetting > 99) 
          return { status1: "positive-1"};
        else if (highSetting > 59)
          return { status1: "neutral"}
        else return { status1: "negative-1" }
      
      case "rearWing":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59) 
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }

      case "engineCooling":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59) 
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }

        case "brakeCooling":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59) 
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }

        case "transmissionRatios":
        if (highSetting > 99)
          return { status1: "positive-2", status2: "negative-2" }
        else if (highSetting > 79)
          return { status1: "positive-1", status2: "negative-1" }
        else if (highSetting > 59) 
          return { status1: "neutral", status2: "neutral" }
        else if (highSetting > 39)
          return { status1: "negative-1", status2: "positive-1" }
        else return { status1: "negative-2", status2: "positive-2" }
    } 
    return "neutral"
  }

  const partInfo = partInfos[carPart.partType || "frontWing"] || { title1: "", title2: "" }; 
  const { status1, status2 }: any = getStatus(carPart.partType || "", carPart.highSettingRange || 100);
  return (
    <GridItem>
      <RBaseCard h="7.5rem" w="full" cursor="default">
        <IconCornerCheckmarkLight position="absolute" top="0" right="0" />
        
        <Flex flexDirection="row" width="full" position="relative" alignItems="end" padding="3" pointerEvents="none">
          <PartInfo title1={partInfo.title1} title2={partInfo.title2} status1={status1} status2={status2} px="0" barWidth={{ base: 9, md: 7}} />          
          <Flex
            h="6rem"
            flexDir="column"
            justifyContent="flex-end"
          >
            <Heading
              as="h2"
              fontSize="0.875rem"
              fontWeight={400}
              color={'white.95'}
            >
              {partMetadata?.name === 'tyres' ? carPart?.name : carPart?.id}
            </Heading>            
          </Flex>
          {partMetadata?.name === 'tyres' && (
            <Flex position="absolute" right={{ base: "135", md: '105' }} top="8"> 
              <RTooltip
                label={`${carPart?.id} - ${carPart?.name}`}
                shouldWrapChildren
              >
                {tyreIcon[partMetadata.tyreId as Tyres]}
              </RTooltip>
            </Flex>
          )}
          
          {partMetadata?.name !== 'tyres' && (
            <>
              <RTooltip
                label={`${carPart?.id} - ${carPart?.name}`}
                shouldWrapChildren
              >
                <RImage 
                  src={partMetadata?.path || ''}
                  alt={partMetadata?.name || 'part'}
                  width={0}
                  height={0}
                  ml={{ base: "2.5rem", md: "1rem" }}
                  style={{ width: '9rem', height: '85%' }}
                  p="1"
                />     
              </RTooltip>
            </>
          )}
        </Flex>

        <RBaseCardGradient 
          gradientColor={isFirstCar ? 'red' : 'green'}
          variant="gradientToTransparent"
          cursor="default"
          hasInsetBorder
        />
      </RBaseCard>
    </GridItem>
  );
};

export default RPerformancePartCard;