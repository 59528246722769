import { Button, Flex, Text, ToastId, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Cars } from "api/generated/graphql";
import { RInput, RLoadingAnimation, RModal, RToast } from "components";
import { useSaveSavedSetups } from "hooks";
import { useRef, useState } from "react";

type SavedSetupNameModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedCar: Cars | undefined;
};

const SavedSetupNameModal = ({ isOpen, onClose, selectedCar }: SavedSetupNameModalProps) => {
  const toast = useToast();
  const toastIdRef = useRef<ToastId[]>([]);
  const queryClient = useQueryClient();
  const { mutateAsync: saveSavedSetups, isLoading: isLoadingSave } = useSaveSavedSetups({
    onSuccess: () => {
      const id = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="success"
            title={`Setup successfully saved`}
            onClose={() => {
              if (toastIdRef.current.includes(id)) {
                toast.close(id);
                toastIdRef.current = toastIdRef.current.filter(
                  (toastId) => toastId !== id
                );
              }
            }}
          />
        ),
      });
      toastIdRef.current.push(id);
      onClose();
    },
    onError: (error: unknown) => {
      const id = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title={`This setup could not be saved`}
            description={`${
              error
            }`}
            onClose={() => {
              if (toastIdRef.current.includes(id)) {
                toast.close(id);
                toastIdRef.current = toastIdRef.current.filter(
                  (toastId) => toastId !== id
                );
              }
            }}
          />
        ),
      });
      toastIdRef.current.push(id);
    },
  });
  const [setupName, setSetupName] = useState("");

  const handleSave = async () => {
    if (!selectedCar) return;

    const setupParts = {
      frontWingId: selectedCar.frontWing?.id,
      rearWingId: selectedCar.rearWing?.id,
      brakeCoolingId: selectedCar.brakeCooling?.id,
      engineCoolingId: selectedCar.engineCooling?.id,
      transmissionRatioId: selectedCar.transmissionRatio?.id,
    };

    const filteredSetupParts = Object.fromEntries(
      Object.entries(setupParts).filter(([_, value]) => value !== undefined)
    );

    try {
      await saveSavedSetups({
        SavedSetupsInput: {
          name: setupName,
          carId: selectedCar.id,
          ...filteredSetupParts,
        },
      });
      queryClient.invalidateQueries({
        queryKey: ['getSavedSetups'],
      });
      onClose();
    } catch (error) {
      console.error("Error saving setup:", error);
    }
  };

  return (
    <RModal
      title="New Saved Setup"
      isOpen={isOpen}
      onClose={onClose}
      variant="darkGrey"
      size="md"
      isCentered
    >
      {isLoadingSave ? (
        <Flex w="full" alignItems="center" justifyContent="center">
          <RLoadingAnimation />
        </Flex>
      ) : (
        <Flex
          flexDir="column"
          p="4"
        >
          <RInput
            value={setupName}
            onChange={(e) => setSetupName(e.target.value)}
            label="Name"
          />
          <Text
            color="paleSlate.100"
          >
            New Saved Setups only apply to the currently selected car and parts within Car Setup
          </Text>
          <Button
            w="full"
            mt="2rem"
            onClick={handleSave}
            isDisabled={!setupName.trim()}
          >
            SAVE
          </Button>
        </Flex>
      )}
    </RModal>
  )
};

export default SavedSetupNameModal;