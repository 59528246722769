import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

const Modal = ({
  title,
  children,
  ...rest
}: ModalProps & {
  title: string;
}) => {
  return (
    <ChakraModal {...rest}>
      <ModalOverlay />
      <ModalContent p={0}>
        <ModalHeader>
          {title}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody p={0} flexDir="column">
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
