import { cloneDeep } from 'lodash';
import { PartGroup, SVGIcon } from 'types';

interface PartMapping {
  name: string;
  screenName?: string;
  icon?: SVGIcon; // icon is used by tyres in part card
  path?: string;
  code?: string;
  tyreId?: string;
}

export const partsData: PartMapping[] = [
  {
    name: 'frontWing',
    screenName: 'Front Wing',
    path: '/images/parts/front-wing.png',
    code: 'FW',
  },
  {
    name: 'rearWing',
    screenName: 'Rear Wing',
    path: '/images/parts/rear-wing.png',
    code: 'RW',
  },
  {
    name: 'engineCooling',
    screenName: 'Engine Cooling',
    path: '/images/parts/engine-cover.png',
    code: 'EC',
  },
  {
    // multiple entries for Brake Cooling for when spelling is fixed
    name: 'brakeCooling',
    screenName: 'Brake Cooling',
    path: '/images/parts/brakes.png',
    code: 'BD',
  },
  {
    name: 'brakeCooling',
    screenName: 'Brake Cooling',
    path: '/images/parts/brakes.png',
    code: 'BD',
  },
  {
    name: 'transmissionRatio',
    screenName: 'Transmission',
    path: '/images/parts/transmission.png',
    code: 'UB',
  },
  {
    name: 'transmissionRatios',
    screenName: 'Transmission',
    path: '/images/parts/transmission.png',
    code: 'UB',
  },
];

export const setAppliedParts = ({
  carouselData,
  appliedPartIds,
}: {
  carouselData: PartGroup[];
  appliedPartIds: string[];
}) => {
  const cloned = cloneDeep(carouselData);
  cloned.forEach(({ partsInGroup }) => {
    partsInGroup.forEach((part) => {
      if (appliedPartIds.includes(part.id || '')) {
        part.isApplied = true;
      }
    });
  });

  return cloned as PartGroup[];
};
