import { defineStyleConfig } from '@chakra-ui/react';

import { defaultGradient, colors } from 'utils/themeConstants';

export const drawerTheme = defineStyleConfig({
  baseStyle: {
    dialog: {
      background: defaultGradient(100),
    },
    closeButton: {
      bg: 'white.10',
      borderRadius: '0',
      position: 'static',
      marginLeft: 'auto',
    },
    header: {
      background: colors.white[10],
      backdropFilter: 'blur(10px)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    body: {
      display: 'flex',
      flexGrow: '1',
      backdropFilter: 'blur(10px)',
    },
    footer: {
      width: '100%',
      background: colors.white[10],
      borderTop: `1px solid ${colors.white[20]}`,
      backdropFilter: 'blur(10px)',
      p: '6',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  sizes: {
    sm: {
      dialog: {
        minW: '100%',
      },
    },
    md: {},
    lg: {},
  },
  defaultProps: {},
});
