/* eslint-disable react-hooks/exhaustive-deps */
import { Text, Heading, Flex } from '@chakra-ui/react';
import { RaceParticipants, Races } from 'api/generated/graphql';

import { useGetRaceResultsById, useGetRaceTips } from 'hooks';
import { RLoadingAnimation } from 'components';
import { IconEmptySet } from 'icons';
import { CarPerformanceStats } from 'components/PerformanceModal/CarPerformanceStats';
import { getSpiderData } from 'components/PerformanceModal/ChartData';
import { useEffect } from 'react';
import { AidrianaTips } from 'components/PerformanceModal/AidrianaTips';

// TODO refactor copy/pasta from Performance Modal into shareable code
const Performance = ({
  race,
  currentPlayerId, // note this is participant ID from the race (not user ID)
}: {
  race: Races;
  currentPlayerId: string;
}) => {
  const { data: raceData, isLoading: isLoadingRaceResults } =
    useGetRaceResultsById(race.id);
  const results = raceData?.getRaceResults;
  const playerAtPosition = (position: number) => {
    return results?.playersParticipants.find((p) => p.position === position);
  };
  const firstPlacePlayer = playerAtPosition(1);

  // if the current user placed 1st, compare to 2nd place
  // if the current user didn't place first, compare to 1st place
  const comparisonPlayer =
    firstPlacePlayer?.id === currentPlayerId
      ? playerAtPosition(2)
      : results?.playersParticipants.find((p) => p.id === currentPlayerId);

  const { data: raceTipsData, isLoading: isLoadingRaceTips } = useGetRaceTips(
    race.id
  );

  const isLoading = isLoadingRaceResults || isLoadingRaceTips;

  const getScores = (participant: RaceParticipants) => {
    return [
      participant?.topSpeedScore,
      participant?.highSpeedScore,
      participant?.lowSpeedScore,
      participant?.brakingScore,
      participant?.coolingScore,
    ];
  };

  const chartData = getSpiderData({
    data1: getScores(firstPlacePlayer as RaceParticipants),
    data2: getScores(comparisonPlayer as RaceParticipants),
  });

  useEffect(() => {
    console.log(chartData);
  }, []);

  if (isLoading) {
    return (
      <Flex w="full" h="full" alignItems="center" justifyContent="center">
        <RLoadingAnimation />
      </Flex>
    );
  }

  return (
    <>
      <Text fontSize="md" mb="6" color="white.60">
        Here&apos;s a view of your recent lap performance for qualifying. You
        can can also view the most recent parts you have on your car.
      </Text>

      {!race.userInRace && (
        <Flex
          width="full"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="80%"
        >
          <IconEmptySet mb="1rem" />
          <Heading as="h2" size="md" textTransform="uppercase">
            No Results to See Here
          </Heading>
        </Flex>
      )}

      {race.userInRace && firstPlacePlayer && comparisonPlayer && (
        <>
          <AidrianaTips
            tips={raceTipsData?.getRaceTips as string[]}
            header={false}
          />

          <Flex gap={4} w="full" px={4} py={8}>
            <CarPerformanceStats
              player={firstPlacePlayer as RaceParticipants}
              comparisonPlayer={comparisonPlayer as RaceParticipants}
              spiderData={chartData}
              currentPlayerId={currentPlayerId}
              style="comparisson"
            />
          </Flex>
        </>
      )}
    </>
  );
};

export default Performance;
