import { Tag, Flex, Box } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

import { RaceStatus as RaceStatusType, Size } from 'types';

const MotionTag = motion(Tag);
const MotionBox = motion(Box);

const breathingAnimation = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 0.5,
    transition: {
      duration: 0.8,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'reverse',
    },
  },
};

const RaceStatus = ({
  status,
  size = 'md',
}: {
  status: RaceStatusType;
  size?: Size;
}) => {
  const bgLookup: { [K in RaceStatusType]: string } = {
    open: 'treasuredTeal.100',
    upcoming: 'creamyOrangeBlush.100',
    live: 'bloodMoon.100',
    closed: 'alexandria.100',
    ended: 'cerebralGrey.100',
    canceled: '',
    postponed: '',
    qualifying: 'alexandria.100',
    tuning: 'perrywinkle.100',
  };

  const controls = useAnimation();

  useEffect(() => {
    if (status === 'live') {
      controls.start('visible');
    } else {
      controls.stop();
      controls.set('hidden');
    }
  }, [status, controls]);

  return (
    <Flex alignItems="center">
      {status === 'live' && (
        <MotionBox
          bgColor="bloodMoon.100"
          w="0.75rem"
          h="0.75rem"
          borderRadius="100%"
          display="inline-block"
          mr="0.5rem"
          variants={breathingAnimation}
          initial="hidden"
          animate={controls}
        />
      )}

      <MotionTag
        size={size}
        textTransform={status === 'live' ? 'uppercase' : 'capitalize'}
        width="fit-content"
        bg={bgLookup[status as keyof typeof bgLookup]}
        color={status === 'live' ? 'white' : 'black.60'}
        variants={breathingAnimation}
        initial="hidden"
        animate={controls}
      >
        {status === 'closed' || status === 'qualifying'
          ? 'Sign-up Closed'
          : status}
      </MotionTag>
    </Flex>
  );
};

export default RaceStatus;
