import {
  List,
  ListItem,
  Flex,
  ListItemProps,
  Text,
  Box,
  FlexProps,
} from '@chakra-ui/react';
import startCase from 'lodash.startcase';

import {
  IconRaincloud,
  IconWind,
  IconRoadway,
  IconFlag,
  IconWindsock,
  IconBumpyRoad,
  IconWheel,
  IconTemperature,
} from 'icons';
import { RTooltip } from 'components';
import {
  AirTemperatureCategory,
  Races,
  TrackTemperatureCategory,
} from 'api/generated/graphql';
import { RecursivePartial, SVGIcon } from 'types';
import { AirTemperatureColors, TrackTemperatureColors } from './RaceInfoTypes';

const Item = ({
  title,
  description,
  Icon,
  tooltip,
  defaultColor = '#71D3FF',
  fromColor = 'rgba(113, 211, 255, 0)',
  toColor = 'rgba(113, 211, 255, 0.4)',
  ...rest
}: ListItemProps & {
  title: string;
  description: string;
  defaultColor?: string;
  fromColor?: string;
  toColor?: string;
  Icon: SVGIcon;
  tooltip: string;
}) => {
  return (
    <ListItem {...rest}>
      <RTooltip label={tooltip}>
        <Flex
          flexDir="column"
          alignItems="center"
          w="full"
          px="0.063rem"
          flexGrow={1}
        >
          <Text fontSize="0.75rem" fontWeight={600} noOfLines={1}>
            {title}
          </Text>
          <Flex
            pos="relative"
            py="0.5rem"
            bgColor="white.40"
            w="full"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              h="1.25rem"
              __css={{
                path: { fill: 'caviar.100' },
              }}
            />
            <Box
              pos="absolute"
              w="full"
              h="full"
              left={0}
              bottom={0}
              bg={`linear-gradient(180deg, ${fromColor} 49.5%, ${toColor} 100%)`}
              zIndex={100}
            />
          </Flex>
          <Text
            fontSize="0.75rem"
            textAlign="center"
            fontWeight={500}
            color={defaultColor}
          >
            {description}
          </Text>
        </Flex>
      </RTooltip>
    </ListItem>
  );
};

const RaceInfo = ({
  race,
  hasDetails = false,
  ...props
}: {
  race: RecursivePartial<Races>;
  hasDetails?: boolean;
} & FlexProps) => {
  if (hasDetails && race) {
    return (
      <Flex flexDirection="column" w="full" pt="6" {...props}>
        <List
          display="grid"
          gridTemplateColumns="repeat(6, 1fr)"
          w="full"
          justifyContent="space-between"
        >
          <Item
            tooltip="Track Temp"
            title="Track Temp"
            description={startCase(race.trackTemperatureCategory || '')}
            Icon={IconTemperature}
            defaultColor={
              TrackTemperatureColors[
                race.trackTemperatureCategory || TrackTemperatureCategory.Cold
              ].defaultColor
            }
            fromColor={
              TrackTemperatureColors[
                race.trackTemperatureCategory || TrackTemperatureCategory.Cold
              ].fromColor
            }
            toColor={
              TrackTemperatureColors[
                race.trackTemperatureCategory || TrackTemperatureCategory.Cold
              ].toColor
            }
          />
          <Item
            tooltip="Air Temp"
            title="Air Temp"
            description={startCase(race.airTemperatureCategory || '')}
            Icon={IconWind}
            defaultColor={
              AirTemperatureColors[
                race.airTemperatureCategory || AirTemperatureCategory.Cold
              ].defaultColor
            }
            fromColor={
              AirTemperatureColors[
                race.airTemperatureCategory || AirTemperatureCategory.Cold
              ].fromColor
            }
            toColor={
              AirTemperatureColors[
                race.airTemperatureCategory || AirTemperatureCategory.Cold
              ].toColor
            }
          />

          <Item
            tooltip="Wind Speed"
            title="Wind"
            description={startCase(race.windCategory || '')}
            Icon={IconWindsock}
            defaultColor="rgba(255, 143, 115, 1)"
            fromColor="rgba(255, 143, 115, 0.1)"
            toColor="rgba(255, 143, 115, 0.7)"
          />

          <Item
            tooltip="Precipitation"
            title="Rainfall"
            description={startCase(race.weatherCategory || '')}
            Icon={IconRaincloud}
            defaultColor="teal.100"
            fromColor="rgba(113, 211, 255, 0.1)"
            toColor="rgba(113, 211, 255, 0.7)"
          />

          <Item
            tooltip="Tarmac Condition"
            title="Track Grip"
            description={startCase(race.tarmacConditionCategory || '')}
            Icon={IconBumpyRoad}
            defaultColor="rgba(255, 227, 128, 1)"
            fromColor="rgba(255, 227, 128, 0.1)"
            toColor="rgba(255, 227, 128, 0.7)"
          />

          <Item
            tooltip="Bumpiness"
            title="Roughness"
            description={startCase(race.trackRoughnessCategory || '')}
            Icon={IconWheel}
            defaultColor="rgba(121, 242, 192, 1)"
            fromColor="rgba(121, 242, 192, 0.1)"
            toColor="rgba(121, 242, 192, 0.7)"
          />
        </List>
      </Flex>
    );
  }
  if (!hasDetails && race) {
    return (
      <List
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        w="full"
        justifyContent="space-between"
        pt="4"
      >
        <Item
          tooltip="Number of Laps"
          title="Laps"
          description={`${race?.laps}`}
          Icon={IconFlag}
          defaultColor="teal.100"
          fromColor="rgba(113, 211, 255, 0.1)"
          toColor="rgba(113, 211, 255, 0.7)"
        />
        <Item
          tooltip="Air Temp"
          title="Air Temp"
          description={startCase(race.airTemperatureCategory || '')}
          Icon={IconWind}
          defaultColor={
            AirTemperatureColors[
              race.airTemperatureCategory || AirTemperatureCategory.Cold
            ].defaultColor
          }
          fromColor={
            AirTemperatureColors[
              race.airTemperatureCategory || AirTemperatureCategory.Cold
            ].fromColor
          }
          toColor={
            AirTemperatureColors[
              race.airTemperatureCategory || AirTemperatureCategory.Cold
            ].toColor
          }
        />
        <Item
          tooltip="Track Temp"
          title="Track Temp"
          description={startCase(race.trackTemperatureCategory || '')}
          Icon={IconRoadway}
          defaultColor={
            TrackTemperatureColors[
              race.trackTemperatureCategory || TrackTemperatureCategory.Cold
            ].defaultColor
          }
          fromColor={
            TrackTemperatureColors[
              race.trackTemperatureCategory || TrackTemperatureCategory.Cold
            ].fromColor
          }
          toColor={
            TrackTemperatureColors[
              race.trackTemperatureCategory || TrackTemperatureCategory.Cold
            ].toColor
          }
        />
        <Item
          tooltip="Precipitation"
          title="Rainfall"
          description={startCase(race.weatherCategory || '')}
          Icon={IconRaincloud}
          defaultColor="teal.100"
          fromColor="rgba(113, 211, 255, 0.1)"
          toColor="rgba(113, 211, 255, 0.7)"
        />
      </List>
    );
  }

  return null;
};

export default RaceInfo;
