// used for race entries and results lists
import { useState } from 'react';
import {
  Text,
  Flex,
  Tag,
  Heading,
  Skeleton,
  FlexProps,
  Button,
} from '@chakra-ui/react';
import shuffle from 'lodash.shuffle';

import { RaceParticipants } from 'api/generated/graphql';
import {
  RCarCard,
  RBaseCardTopLeft,
  RTooltip,
  RTiers,
  RBaseCardTopRight,
} from 'components';
import { AppliedLivery, Livery, RarityTier, Tyres } from 'types';
import {
  IconEmptySet,
  IconEyeHidden,
  IconEyeShow,
  IconTyreC1,
  IconTyreC2,
  IconTyreC3,
  IconTyreC4,
  IconTyreC5,
  IconTyreInter,
  IconTyreWet,
} from 'icons';
import Flags, { FlagsProps } from 'components/flags';
import { AvatarPicture } from 'components/Avatar/AvatarPicture';
import { useResults } from 'context';
import { BaseCardProps } from 'components/CarCard/CarBaseCard';

const RaceGrid = ({
  playersParticipants,
  text,
  gridType,
  carCardProps,
}: {
  playersParticipants: RaceParticipants[];
  text: string;
  gridType: 'entries' | 'results';
  carCardProps?: BaseCardProps & FlexProps;
}) => {
  const [hideResults, setHideResults] = useState(gridType === 'results');
  const { showAllResults, setShowAllResults } = useResults();
  const filtered = playersParticipants?.filter((playersParticipant) => {
    return playersParticipant?.status !== 'rejected'; // possible values are processing, accepted, rejected
  });

  const sorted =
    gridType === 'entries'
      ? shuffle(filtered)
      : filtered.sort((a, b) => {
          if (a?.position && b?.position) {
            return a?.position - b?.position;
          }

          return 0;
        });

  const tyreIcons: Record<Tyres, JSX.Element> = {
    C1: <IconTyreC1 h="2rem" w="auto" />,
    C2: <IconTyreC2 h="2rem" w="auto" />,
    C3: <IconTyreC3 h="2rem" w="auto" />,
    C4: <IconTyreC4 h="2rem" w="auto" />,
    C5: <IconTyreC5 h="2rem" w="auto" />,
    INTER: <IconTyreInter h="2rem" w="auto" />,
    WET: <IconTyreWet h="2rem" w="auto" />,
  };

  return (
    <Flex flexDirection="column">
      <Text mb="6" fontSize="md" color="white.60">
        {text}
      </Text>

      {gridType === 'results' && (
        <Button
          variant="secondary"
          w="full"
          mb="6"
          onClick={() => setShowAllResults((oldState) => !oldState)}
        >
          {showAllResults ? 'Hide' : 'Show'} Results{' '}
          {showAllResults ? (
            <IconEyeShow ml="0.5rem" />
          ) : (
            <IconEyeHidden ml="0.5rem" />
          )}
        </Button>
      )}

      {!showAllResults && sorted.length === 0 && (
        <Flex
          width="full"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <IconEmptySet mb="1rem" />

          <Heading as="h2" size="md" textTransform="uppercase">
            No Entries Yet
          </Heading>
        </Flex>
      )}

      <Flex pb="20" flexDirection="column">
        {sorted.map((playersParticipant, index) => {
          const { car } = playersParticipant;

            return (
              <RCarCard
                key={index}
                backgroundPreload
                playersParticipant={playersParticipant}
                livery={car?.livery as Livery}
                appliedLivery={car?.appliedLivery as AppliedLivery}
                mb="6"
                showResultsFooter={gridType === 'results'}
                imageProps={{
                  transform: 'scale(70%)',
                  marginBottom: '-1rem',
                }}
                {...carCardProps}
              >
                <RBaseCardTopLeft>
                  <Flex
                    flexDirection="column"
                    gap="10px"
                    justifyContent="center"
                  >
                    <Skeleton isLoaded={showAllResults}>
                      <Flex alignItems="center" gap={['2', '3']}>
                        {playersParticipant?.position && (
                          <RTooltip label="Finishing Position">
                            <Tag bg="white.80" color="black.80">
                              {playersParticipant.position}
                            </Tag>
                          </RTooltip>
                        )}

                        <Text fontSize={['sm', 'md']} maxW="5rem" noOfLines={1}>
                          {car?.name || 'New Car'}
                        </Text>
                      </Flex>
                    </Skeleton>
                    <Skeleton w="50px" isLoaded={showAllResults}>
                      <Flex alignItems="center" h="1rem" gap="1">
                        {/* <RTiers
                        tierType="car"
                        tier={playersParticipant.tier?.name as CarTier}
                      /> */}
                        <RTiers
                          tierType="rarity"
                          tier={car.rarity as RarityTier}
                        />
                        {car?.tyres?.id && tyreIcons[car?.tyres?.id as Tyres]}
                      </Flex>
                    </Skeleton>
                  </Flex>
                </RBaseCardTopLeft>
                <RBaseCardTopRight maxW="50%">
                  <Flex alignItems="center" h="1.5rem">
                    <Flags
                      countryCode={
                        showAllResults
                          ? (playersParticipant?.user
                              ?.countryFlag as FlagsProps['countryCode']) ||
                            'default'
                          : 'default'
                      }
                    />
                    <Skeleton
                      isLoaded={showAllResults}
                      ml={showAllResults ? '' : '1rem'}
                    >
                      <AvatarPicture
                        key={playersParticipant.user.avatarId}
                        avatarId={playersParticipant.user.avatarId || ''}
                      />
                    </Skeleton>
                    <Skeleton
                      isLoaded={showAllResults}
                      ml={showAllResults ? '' : '1rem'}
                    >
                      <Text fontSize={['sm', 'md']} noOfLines={1}>
                        {playersParticipant?.user?.playerName || 'Owner Name'}
                      </Text>
                    </Skeleton>
                  </Flex>
                </RBaseCardTopRight>
              </RCarCard>
            );
          })}
      </Flex>
    </Flex>
  );
};

export default RaceGrid;
