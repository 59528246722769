import { Flex, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { Icon2Cars, IconCheckeredFlag, IconUpgrade } from 'icons';
import { useRouter } from 'next/router';

const UpgradeCounter = ({
  timesUsed,
  maximumTimesUsed,
  isGarage,
  isTrialCar,
  carId,
}: {
  timesUsed: number;
  maximumTimesUsed: number | null;
  isGarage?: boolean;
  isTrialCar?: boolean;
  carId: string | undefined;
}) => {
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const percentageUsed = maximumTimesUsed
    ? (timesUsed / maximumTimesUsed) * 100
    : 0;

  return (
    <Tooltip label="Races Completed Within Limit" openDelay={500} isOpen={isOpen}>
      <Flex
        justifyContent={{ base: 'center', md: 'space-between' }}
        minW={isGarage ? '6rem' : { base: '10rem', md: '15rem' }}
        ml={isGarage ? 0 : { base: 0, md: 'auto' }}
        alignItems="center"
        zIndex={10}
        onClick={(e) => {
          e.stopPropagation();
          if (timesUsed === maximumTimesUsed) {
            router.push(
              isTrialCar ? '/marketplace/renew-trial-car' : `/salvage/${carId}`
            );
          }
        }}
        px={{ base: '1', md: '3' }}
        py={{ base: '1', md: '2' }}
        position="relative"
        bg="rgba(0, 0, 0, 0.2);"
        cursor={timesUsed === maximumTimesUsed ? 'pointer' : 'not-allowed'}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        backgroundImage={`linear-gradient(to right, ${timesUsed === maximumTimesUsed
            ? isOpen ? 'baltic.100' : 'rgba(26, 116, 107, 1)'
            : 'rgba(26, 116, 107, 1)'
          } ${percentageUsed}%, rgba(0, 0, 0, 0.2) ${percentageUsed}%)`}
        _hover={{
          backgroundImage: `linear-gradient(to right, ${timesUsed === maximumTimesUsed ? 'baltic.100' : 'treasuredTeal.100'
            } ${percentageUsed}%, rgba(0, 0, 0, 0.2) ${percentageUsed}%)`,
          transition: 'all 0.3s ease',
          cursor: timesUsed === maximumTimesUsed ? 'pointer' : 'not-allowed',
        }}
      >
        {!isGarage && (
          <Text
            fontFamily={'heading'}
            fontSize={{ base: 'xs', md: 'md' }}
            color={timesUsed === maximumTimesUsed ? 'black.95' : 'white.80'}
            zIndex={1} // Keep text above the background
          >
            {isTrialCar ? 'UPGRADE' : 'SALVAGE'}
          </Text>
        )}

        <Flex
          alignItems="center"
          gap={{ base: '1', md: '2' }}
          zIndex={1} // Keep text above the background
        >
          <Text
            fontFamily={'body'}
            fontSize={{ base: 'md', md: 'lg' }}
            color={timesUsed === maximumTimesUsed ? 'black.95' : 'white.80'}
          >
            {timesUsed}/{maximumTimesUsed}
          </Text>
          {timesUsed === maximumTimesUsed ? (
            isTrialCar ? (
              <IconUpgrade
                w={{ base: '1rem', md: '1.25rem' }}
                sx={{
                  '& path': {
                    fill: 'black.95',
                  },
                }}
              />
            ) : (
              <Icon2Cars
                w={{ base: '1rem', md: '1.25rem' }}
                sx={{
                  '& path': {
                    fill: 'black.95',
                  },
                }}
              />
            )
          ) : (
            <IconCheckeredFlag
              w={{ base: '1rem', md: '1.25rem' }}
              sx={{
                '& path': {
                  stroke: 'white.80',
                  fill: 'white.80',
                },
              }}
            />
          )}
        </Flex>
      </Flex>
    </Tooltip>
  );
};

export default UpgradeCounter;