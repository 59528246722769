import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconShare = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M5.5 7h8.594L9.156 2.906c-.219-.187-.219-.5-.062-.719.187-.218.5-.218.719-.062l6 4.969c.093.125.187.281.187.406s-.094.281-.188.375l-6 4.969c-.093.062-.218.125-.312.125a.504.504 0 0 1-.406-.188c-.156-.219-.156-.531.062-.719l4.938-4.093H5.5c-2.5 0-4.5 2-4.5 4.468v.97C1 13.78.75 14 .5 14a.494.494 0 0 1-.5-.5v-.969C0 9.47 2.438 7 5.5 7Z"
    />
  </svg>
));
export default SvgIconShare;
