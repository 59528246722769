import { Button, Flex, Text } from '@chakra-ui/react';

import { RModal } from 'components';

interface SetAvatarNameModalProps {
  parts?: string;
  isOpen: boolean;
  onClose: () => void;
  confirmFn: () => void;
}

const RepairPartsModal = ({
  parts,
  isOpen,
  onClose,
  confirmFn,
}: SetAvatarNameModalProps) => {
  const handleConfirm = async () => {
    confirmFn();
    onClose();
  };

  return (
    <RModal
      variant="darkGrey"
      title="REPAIR PARTS"
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <Flex direction="column" p={4}>
        <Text fontSize="md" mb="1rem" textAlign="center">
          Are you sure? This will change part [{parts}] for a new one
          that may have slightly different performance characteristics.
        </Text>
        <Flex gap={4}>
          <Button
            w="full"
            mt="2rem"
            onClick={onClose}
            variant="secondary-outlined"
          >
            Cancel
          </Button>
          <Button w="full" mt="2rem" onClick={handleConfirm}>
            Repair
          </Button>
        </Flex>
      </Flex>
    </RModal>
  );
};

export default RepairPartsModal;
