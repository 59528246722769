import { defineStyleConfig } from '@chakra-ui/react';

import { defaultTransition } from 'utils/themeConstants';

// TODO rename variants
const secondary = {
  bgColor: 'white.10',
  color: 'white.80',
  ':hover, :focus': {
    bgColor: 'white.20',
    _disabled: {
      bgColor: 'white.10',
    },
  },
};

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    borderRadius: '0',
    fontFamily: 'heading',
    color: 'white.100',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    transition: `${defaultTransition}, width 0s`,
    _disabled: {
      opacity: 0.6,
    },
  },
  variants: {
    primary: {
      bgColor: 'bloodMoon.100',
      ':hover, :focus': {
        bgColor: 'fieryCoral.100',
        _disabled: {
          bgColor: 'bloodMoon.100',
        },
      },
    },
    'primary-link': {
      bgColor: 'transparent',
      color: 'fieryCoral.100',
      p: 0,
      textDecoration: 'underline',
      fontFamily: 'body',
      ':hover, :focus': {
        color: 'fieryCoral.80',
        _disabled: {
          opacity: 0.6,
        },
      },
    },
    ghost: {
      bgColor: 'transparent',
      color: 'white.100',
      opacity: 0.8,
      border: 'none',
      borderColor: 'none',
      ':hover, :focus': {
        opacity: 1,
        bgColor: 'transparent',
        color: 'white.100',
        _disabled: {
          bgColor: 'transparent',
        },
      },
    },
    'primary-ghost': {
      bgColor: 'bloodMoon.40',
      border: '1px solid',
      borderColor: 'fieryCoral.100',
      ':hover, :focus': {
        bgColor: 'fieryCoral.100',
        _disabled: {
          bgColor: 'bloodMoon.40',
        },
      },
    },
    secondary,
    'secondary-outlined': {
      ...secondary,
      border: '1px solid',
      borderColor: 'white.40',
    },
    tertiary: {
      bgColor: 'baltic.100',
      ':hover, :focus': {
        bgColor: 'treasuredTeal.100',
        _disabled: {
          bgColor: 'baltic.100',
        },
      },
    },
    icon: {
      bgColor: 'eerieBlack.100',
      text: 'white.80',
      _hover: {
        bgColor: 'jet.100',
      },
    },
  },
  // sizes: {
  //   sm: {
  //     fontSize: '14px',
  //     lineHeight: '20px',
  //     paddingLeft: '8px',
  //     paddingRight: '8px',
  //     paddingTop: '4px',
  //     paddingBottom: '4px',
  //     height: 'auto',
  //   },
  //   md: {
  //     fontSize: '14px',
  //     lineHeight: '20px',
  //     paddingLeft: '12px',
  //     paddingRight: '12px',
  //     paddingTop: '7px',
  //     paddingBottom: '7px',
  //     height: 'auto',
  //   },
  //   lg: {
  //     fontSize: '16px',
  //     lineHeight: '16px',
  //     paddingLeft: '20px',
  //     paddingRight: '20px',
  //     paddingTop: '13px',
  //     paddingBottom: '13px',
  //     height: 'auto',
  //   },
  // },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});
