import { Flex, Box } from '@chakra-ui/react';
import { RaceParticipants } from 'api/generated/graphql';

import { RPartCard } from 'components';
import { IconDoubleArrow } from 'icons';
import { CarPart, PartTypes } from 'types';

const PartComparison = ({
  partType,
  firstPlacePlayer,
  comparisonPlayer,
}: {
  partType: Exclude<PartTypes, 'transmissionRatio'>; // TODO the exclude on transmissionRatio is temporary until supported by the API
  firstPlacePlayer: Partial<RaceParticipants> | undefined;
  comparisonPlayer: Partial<RaceParticipants> | undefined;
}) => {
  return (
    <Flex w="full" alignItems="center" justifyContent="space-between">
      <Box w="full" padding={4}>
        <RPartCard
          cursor="default"
          variant="success"
          carPart={{
            partType,
            ...(firstPlacePlayer?.[partType] as CarPart),
          }}
        />
      </Box>

      <IconDoubleArrow w="2rem" />

      <Box w="full" padding={4}>
        <RPartCard
          cursor="default"
          variant="fail"
          carPart={{
            partType,
            ...(comparisonPlayer?.[partType] as CarPart),
          }}
        />
      </Box>
    </Flex>
  );
};

export default PartComparison;
