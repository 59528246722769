import { Box, Flex, Text } from '@chakra-ui/react';
import { RImage } from 'components';
import { Cars } from 'api/generated/graphql';

const getBarColor = (value: number) => {
  if (value >= 0 && value <= 3.99) return 'bloodMoon.100';
  if (value >= 4 && value <= 6) return 'secondaryYellow.100';
  if (value >= 6.01 && value <= 10) return 'baltic.100';
  return 'gray.200'; // Default color for undefined values
};

const TuningBar = ({ value }: { value: number }) => {
  const color = getBarColor(value);
  const bars = Array(10)
    .fill('')
    .map((_, index) => (
      <Box
        key={index}
        w={{ base: '1', md: '2' }}
        h="1"
        bg={index < value ? color : 'gray.800'} // Using gray.800 for the dark gray background
      />
    ));

  return <Flex gap="1">{bars}</Flex>;
};

const TuningDisplay = ({
  tuning,
  selectedCar,
}: {
  tuning: Record<string, number>;
  selectedCar?: Cars;
}) => {
  const tuningEntries = Object.entries(tuning);
  const firstColumn = tuningEntries.slice(0, 3);
  const secondColumn = tuningEntries.slice(3);

  return (
    <Flex
      justifyContent="space-between"
      flexDir={{ base: 'column', md: 'row' }}
      alignItems="center"
      w="100%"
      pb="4"
    >
      <Flex
        position="relative"
        zIndex="1"
        p="4"
        background="linear-gradient(180deg, #000000 44.27%, #272727 72.92%, #353535 100%);"
        justifyContent="center"
        w="9rem"
        mb={{ base: '4', md: '0' }}
        alignItems="center"
      >
        <RImage
          src={
            selectedCar?.appliedLivery?.imageUrl
              ? require(`public/images/cars/livery-${selectedCar?.appliedLivery?.imageUrl}.png`)
              : require(`public/images/cars/livery-${
                  selectedCar?.livery || 'orange'
                }.png`)
          }
          alt=""
          objectPosition="center center"
          position="absolute"
          zIndex="2"
        />

        <RImage
          src={require(`public/images/cars/livery-shadow.png`)}
          alt=""
          position="relative"
          objectPosition="center center"
          zIndex="0"
          filter="blur(2px)"
        />
      </Flex>
      <Flex gap={2}>
        <Flex flexDir="column">
          {firstColumn.map(([key, value]) => (
            <Flex
              key={key}
              alignItems="center"
              justifyContent="flex-end"
              gap="2"
              color="white.60"
            >
              <Text fontSize="sm" textAlign="end">
                {key.toUpperCase()}
              </Text>
              <TuningBar value={value} />
              <Text w="2rem" fontSize="sm">
                {value}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Flex flexDir="column">
          {secondColumn.map(([key, value]) => (
            <Flex
              key={key}
              alignItems="center"
              justifyContent="flex-end"
              gap="2"
              color="white.60"
            >
              <Text fontSize="sm" textAlign="end">
                {key.toUpperCase()}
              </Text>
              <TuningBar value={value} />
              <Text w="2rem" fontSize="sm">
                {value}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TuningDisplay;
