import {
  Button,
  List,
  Flex,
  Spacer,
  Box,
  Menu,
  MenuButton,
  Show,
  Text,
  IconButton,
  useDisclosure,
  Heading,
  Link,
  HStack,
  Divider,
  Avatar,
  Skeleton,
} from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import NextLink from 'next/link';
import router from 'next/router';

import {
  IconRacinoLogo,
  IconHome,
  IconGarage,
  IconHub,
  IconMarket,
  IconBullhorn,
  IconAudio,
  IconHelp,
  IconCheckeredFlag,
  IconChevron,
  IconBarsMenuHamburger,
  IconPencil,
  IconArrowRepeat,
  IconHelmet,
  IconHelmetMenu,
  IconLeaderboard,
} from 'icons';
import routes from 'routes';
import NavItem from './NavItem';
import UserMenu from './UserMenu';
import AudioSettingsModal from './AudioSettingsModal';
import { RDrawer, RImage } from 'components';
import { colors } from 'utils/themeConstants';
import { useUserSettings } from 'hooks';
import { HubDrawer } from 'components/Hub/HubDrawer';
import { useHub } from 'context/hubContext';
import { useUser } from 'context';
import { FundsProvider } from 'context/fundsContext';
import { useState } from 'react';
import overlayBg from 'public/images/backgrounds/gradient-overlay.png';

const dropdownIcon = (
  <IconChevron transform="rotate(90deg)" width=".75rem" height=".6875rem" />
);

const GlobalLayout = ({
  children,
  needsCar,
  onOpenAnyDrawer,
  bgImageSrc,
  bgAnimationPulse,
}: {
  children: React.ReactNode;
  needsCar?: boolean;
  onOpenAnyDrawer?: boolean;
  bgImageSrc?: string;
  bgAnimationPulse?: boolean;
}) => {
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure();
  const {
    isOpen: isAudioSettingsOpen,
    onOpen: onOpenAudioSettings,
    onClose: onCloseAudioSettings,
  } = useDisclosure();
  const {
    isOpen: isDrawerOpen,
    onToggle: onDrawerToggle,
    onClose: onDrawerClose,
  } = useDisclosure();

  const [isWalletOpen, setIsWalletOpen] = useState(false);

  const pathname = usePathname();

  const minW = '40rem';
  const maxW = '90rem';
  const navWidth = '3.5rem';
  const mobileNavHeight = '2.75rem';

  const { getUserSettings } = useUserSettings();
  const { avatarImgSrc } = useUser();

  const { isOpenHub, onCloseHub, onToggleHub } = useHub();

  const getDisplayName = () => {
    if (getUserSettings.isLoading) {
      return <Skeleton width="60%" mr="2" />;
    }

    return (
      <Text
        fontSize="md"
        fontWeight="bold"
        whiteSpace="break-spaces"
        isTruncated
      >
        {getUserSettings.data?.getUser.playerName ||
          getUserSettings.data?.getUser.email.split('@')[0]}
      </Text>
    );
  };

  return (
    <>
      <Show above="lg">
        {/* Dektop */}

        {bgImageSrc && (
          <RImage
            src={bgImageSrc}
            width="0" // width set in style property
            height="0" // height set in style property
            objectFit="cover"
            objectPosition="top left"
            style={{
              width: '100vw',
              height: '100vh',
              zIndex: -2,
              position: 'absolute',
              top: 0,
              left: 0,
              overflow: 'hidden',
            }}
            alt={'background image'}
          />
        )}

        {bgAnimationPulse && (
          <RImage
            src={overlayBg.src}
            width="0" // width set in style property
            height="0" // height set in style property
            objectFit="cover"
            objectPosition="top left"
            className="bg-pulse"
            blendMode="hard-light"
            style={{
              width: '100vw',
              height: '100vh',
              zIndex: -1,
              position: 'absolute',
              top: 0,
              left: 0,
              overflow: 'hidden',
            }}
            alt={'background image'}
          />
        )}

        <Flex
          bg="black.20"
          width={navWidth}
          height="100vh"
          flexDirection="column"
          paddingBottom="4"
          alignItems="center"
          position="absolute"
          top="0"
          left="0"
          zIndex={1401}
        >
          <Flex height={navWidth}>
            <IconRacinoLogo width="2rem" height="auto" />
          </Flex>

          <List
            as="nav"
            textAlign="center"
            display="flex"
            flexDirection="column"
            textTransform="uppercase"
            height={`calc(100% - ${navWidth})`}
            width="100%"
          >
            <NavItem
              text="Home"
              href={routes.root()}
              Icon={IconHome}
              id="home-nav-button"
            />
            <NavItem
              text="Garage"
              href={routes.garage()}
              Icon={IconGarage}
              id="garage-nav-button"
            />
            <NavItem
              text="Racing"
              href={routes.races()}
              Icon={IconCheckeredFlag}
              isActive={pathname === routes.raceResults()}
              id="race-nav-button"
            />

            <NavItem
              text="Leader Board"
              href={routes.leaderboard()}
              Icon={IconLeaderboard}
              id="leaderboard-nav-button"
            />
            <NavItem
              text="Driver"
              href={routes.avatar()}
              Icon={IconHelmetMenu}
              id="avatar-nav-button"
            />

            <NavItem
              text="Market"
              Icon={IconMarket}
              href={routes.marketplace()}
              id="market-nav-button"
            />
            {/* <NavItem text="Hub" Icon={IconHub} onClick={() => onToggleHub()} />
            <HubDrawer isOpen={isOpenHub} onClose={onCloseHub} /> */}

            <Spacer />

            <NavItem
              isDisabled
              text="Audio"
              onClick={onOpenAudioSettings}
              isActive={isAudioSettingsOpen}
              Icon={IconAudio}
              id="audio-nav-button"
            />
            <NavItem
              text="Help"
              isDisabled
              Icon={IconHelp}
              id="help-nav-button"
            />
          </List>
        </Flex>

        <Box ml={navWidth}>
          <Flex
            bg="darkVoid.20"
            backdropBlur="10px"
            alignItems="center"
            height={navWidth}
            paddingLeft="6"
            paddingRight="10" // more padding on the right to account for scrollbar
            minWidth={minW}
          >
            {/* <Heading
            as="h1"
            size="xs"
            textTransform="uppercase"
            fontWeight="normal"
          >
            The future of play to earn performance racing is here...
          </Heading> */}

            <Menu>
              <MenuButton
                pointerEvents="none" // this is a temporary alternative to disabling the button which affects appearance
                as={Button}
                variant="secondary"
                border="0"
                size="sm"
                onClick={() => router.push(routes.races())}
                // rightIcon={dropdownIcon}
              >
                Formula Racing
              </MenuButton>
              <MenuButton
                as={Button}
                variant="secondary"
                border="0"
                size="sm"
                ml="2"
                onClick={() =>
                  window.open('https://guide.racino.io/info', '_blank')
                }
              >
                Guide
              </MenuButton>
              {/* <Link href="https://guide.racino.io/info" target="_blank">

              </Link> */}
            </Menu>

            <Box ml="auto">
              <FundsProvider>
                <UserMenu
                  dropdownIcon={dropdownIcon}
                  setIsWalletOpen={setIsWalletOpen}
                />
              </FundsProvider>
            </Box>
          </Flex>

          <Box
            p="8"
            height="calc(100vh - 4rem)"
            overflow="auto"
            className="red-scrollbar"
            minWidth={minW}
          >
            <Box pos="relative" maxWidth={maxW} marginX="auto" height="full">
              {/* <GarryHelpButton /> */}
              {children}
              {/* <Box height={24} /> */}
            </Box>
          </Box>
        </Box>
      </Show>
      <Show below="lg">
        {/* Mobile */}

        <Flex
          gap={3}
          alignItems="center"
          position="sticky"
          px="5"
          bg="black.20"
          width="full"
          height={mobileNavHeight}
          top="0"
          left="0"
        >
          <IconButton
            ml="-2"
            aria-label="Global Nav Menu"
            icon={<IconBarsMenuHamburger w="1.5rem" />}
            variant="ghost"
            id="hamburger-menu-button"
            onClick={onMenuOpen}
          />
          <Box onClick={() => router.push('/')}>
            <IconRacinoLogo w="1.5rem" height="auto" />
          </Box>
          <Spacer />

          {/* <Heading
      as="h1"
      size="xs"
      textTransform="uppercase"
      fontWeight="normal"
    >
      The future of play to earn performance racing is here...
    </Heading> */}

          <FundsProvider>
            <UserMenu
              dropdownIcon={dropdownIcon}
              setIsWalletOpen={setIsWalletOpen}
            />
          </FundsProvider>
          <RDrawer
            placement="left"
            size="xs"
            isOpen={isMenuOpen}
            onClose={onMenuClose}
            heading={
              <Flex height={navWidth} alignItems="center" gap="3">
                <IconRacinoLogo width="2rem" height="auto" />
                <Heading size="xl" fontWeight="bold">
                  Racino
                </Heading>
              </Flex>
            }
            drawerBody={
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
                flex="1 0 0"
                py="2"
                __css={{
                  path: { fillOpacity: '0.6' },
                }}
              >
                <List
                  h="full"
                  as="nav"
                  display="flex"
                  flexDirection="column"
                  textTransform="uppercase"
                  width="full"
                  gap="2"
                >
                  {/* <Flex
                    alignItems="center"
                    as={NextLink}
                    href={routes.avatar()}
                    px="3"
                    mb="2"
                  >
                    <Box
                      position="relative"
                      borderRadius="full"
                      overflow="hidden"
                      width="3rem"
                      height="3rem"
                      mr="0.5rem"
                      bg={`linear-gradient(180deg, ${colors.black[100]} 44.27%, ${colors.grapeBlue[100]} 72.92%, ${colors.yankeesBlue[100]} 100%)`}
                    >
                      <Avatar
                        size="full"
                        src={avatarImgSrc}
                        position="absolute"
                        w="6.5rem"
                        h="6.5rem"
                        left="-1.7rem"
                        objectFit="cover"
                        objectPosition="center"
                      />
                    </Box>
                    <Text textTransform="none">{getDisplayName()}</Text>

                    <IconPencil width="1rem" ml="auto" />
                  </Flex> */}
                  <Menu>
                    <MenuButton
                      pointerEvents="none" // this is a temporary alternative to disabling the button which affects appearance
                      as={Button}
                      variant="secondary"
                      border="0"
                      size="md"
                      onClick={() => router.push(routes.races())}
                      // rightIcon={dropdownIcon}
                    >
                      <HStack w="full" gap="3">
                        <IconArrowRepeat />
                        <Text>Formula Racing</Text>
                      </HStack>
                    </MenuButton>
                  </Menu>
                  <Divider borderStyle="dashed" />
                  <Button
                    variant="secondary"
                    justifyContent="flex-start"
                    id="avatar-menu-button"
                  >
                    <Link
                      href={routes.avatar()}
                      display="flex"
                      variant="unstyled"
                      justifyContent="flex-start"
                      w="full"
                      h="full"
                    >
                      <HStack w="full" gap="3">
                        <IconHelmetMenu />
                        <Text fontSize="md">Profile</Text>
                      </HStack>
                    </Link>
                  </Button>
                  <Button
                    variant="secondary"
                    justifyContent="flex-start"
                    id="market-menu-button"
                  >
                    <Link
                      href={routes.marketplace()}
                      display="flex"
                      variant="unstyled"
                      justifyContent="flex-start"
                      w="full"
                      h="full"
                    >
                      <HStack w="full" gap="3">
                        <IconMarket />
                        <Text>Marketplace</Text>
                      </HStack>
                    </Link>
                  </Button>
                  {/* <Button variant="secondary" justifyContent="flex-start">
                    <Link
                      // href={routes.hub()}
                      onClick={onToggleHub}
                      display="flex"
                      variant="unstyled"
                      justifyContent="flex-start"
                      w="full"
                      h="full"
                    >
                      <HStack w="full" gap="3">
                        <IconHub />
                        <Text>Hub</Text>
                      </HStack>
                    </Link>
                  </Button>
                  <HubDrawer isOpen={isOpenHub} onClose={onCloseHub} /> */}

                  <Spacer />
                  <Divider borderStyle="dashed" />
                  <Button
                    variant="secondary"
                    justifyContent="flex-start"
                    onClick={onOpenAudioSettings}
                    id="audio-menu-button"
                    isDisabled
                  >
                    <Link
                      // href={routes.audio()}
                      display="flex"
                      variant="unstyled"
                      justifyContent="flex-start"
                      w="full"
                      h="full"
                    >
                      <HStack w="full" gap="3">
                        <IconAudio w="1rem" />
                        <Text>Audio</Text>
                      </HStack>
                    </Link>
                  </Button>
                  <Button
                    variant="secondary"
                    justifyContent="flex-start"
                    isDisabled
                    id="help-menu-button"
                  >
                    <Link
                      // href={routes.help()}
                      display="flex"
                      variant="unstyled"
                      justifyContent="flex-start"
                      w="full"
                      h="full"
                    >
                      <HStack w="full" gap="3">
                        <IconHelp />
                        <Text>Help</Text>
                      </HStack>
                    </Link>
                  </Button>
                </List>
              </Flex>
            }
          />
        </Flex>
        <Box
          p="4"
          pb={{ base: '12', md: '4' }}
          w="100vw"
          h="100vh"
          marginX="auto"
          overflow="auto"
        >
          {children}
          <Box height={24} />
        </Box>
        <Box
          zIndex="9999"
          position="fixed"
          bottom="0"
          left="0"
          w="100vw"
          bg="black.40"
          backdropFilter="blur(10px)"
          display={
            isOpenHub || onOpenAnyDrawer || isWalletOpen ? 'none' : 'block'
          }
        >
          <List
            as="nav"
            textAlign="center"
            display="flex"
            textTransform="uppercase"
            width="100%"
          >
            <NavItem
              text="Home"
              href={routes.root()}
              Icon={IconHome}
              id="home-bottom-nav-button"
            />
            <NavItem
              text="Racing"
              href={routes.races()}
              Icon={IconCheckeredFlag}
              isActive={
                pathname === routes.raceResults() ||
                pathname === routes.leaderboard().split('?')[0]
              }
              id="race-bottom-nav-button"
            />
            <NavItem
              text="Garage"
              href={routes.garage()}
              Icon={IconGarage}
              id="garage-bottom-nav-button"
            />
          </List>
        </Box>
      </Show>
      <AudioSettingsModal
        isOpen={isAudioSettingsOpen}
        onClose={onCloseAudioSettings}
      />
    </>
  );
};

export default GlobalLayout;
