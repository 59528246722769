import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetRaceResults = () => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const racesGql = graphql(`
    query GetRaceResults {
      getRaces(filter: { status: [ended], limit: 500 }) {
        id
        laps
        prizePool
        status
        userInRace

        createdAt
        executedAt
        tuningPeriodEndTime
        qualifyingAnnouncementTime
        startTime
        numberOfTickets

        airTemperatureCategory
        tarmacConditionCategory
        trackRoughnessCategory
        trackTemperatureCategory
        weatherCategory
        windCategory

        playersParticipants {
          id
          position
          status
          time
          points
          earnings
          car {
            id
            name
            livery
            tyresId
            rarity
            # tier {
            #   name
            # }
          }
          user {
            email
            avatarId
            playerName
            countryFlag
          }
        }

        track {
          id
          frontendName
          length
          description
          has2D
          has3D
        }
      }
    }
  `);

  const getRaceResults = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['raceResultsQuery'],
    queryFn: async () => {
      return graphQLClient.request(racesGql);
    },
  });

  return getRaceResults;
};

export default useGetRaceResults;
