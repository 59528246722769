import { useEffect, useState } from 'react';
import { Box, Flex, FlexProps, Heading, Text, Tooltip } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Maybe } from 'graphql/jsutils/Maybe';

import { CarParts, RaceParticipants } from 'api/generated/graphql';
import RSlider from 'components/Slider/Slider';
import { IconDashes, IconTuningHigh, IconTuningLow } from 'icons';
import { PartTypes } from 'types';
import { partsSliderGradient } from 'utils/themeConstants';

export type PartInfosType = {
  [key in PartTypes]: {
    title1: string;
    title2?: string;
  };
};

interface PartSliderProps extends FlexProps {
  part?: Maybe<CarParts>;
  partInfos: PartInfosType;
  userRaceData?: RaceParticipants;
  handleSliderOnChangeEnd: (tunedPart: {
    name: string;
    val: number;
  }) => Promise<void>;
  getPartName: (name: string) => string;
  getMinSuggestion: (part: CarParts) => number;
  getMaxSuggestion: (part: CarParts) => number;
  sliderValue: number;
}

export const PartSlider = ({
  part,
  partInfos,
  userRaceData,
  getMaxSuggestion,
  getMinSuggestion,
  getPartName,
  handleSliderOnChangeEnd,
  sliderValue,
  ...props
}: PartSliderProps) => {
  const [value, setValue] = useState(sliderValue);

  const highSettingRange = part?.highSettingRange || 0;
  const lowSettingRange = part?.lowSettingRange || 0;
  const valueInPercentage =
    ((value - lowSettingRange) / (highSettingRange - lowSettingRange)) * 100;

  const partsDescription = {
    'Front Wing': 'Adjust the balance, more is needed for fast corners and soft tyres.',
    'Rear Wing': 'Adjust the downforce, less is better for long straights.',
    'Engine Mode': 'Adjust the engine power and fuel usage, more is better for long straights.',
    'Engine Cooling': 'Adjust the engine cooling, more is needed when the air temp and the engine mode are high.',
    'Brake Cooling': 'Adjust the brake performance, more is needed on tracks with lots of big stops.',
    'Suspension Ride Height': 'Adjust the ride height of the car to clear large bumps, but lower is better for slow corners.',
    'Suspension Stiffness': 'Adjust the suspension stiffness, softer is better for slow corners.'
  }

  useEffect(() => {
    setValue(sliderValue);
  }, [sliderValue]);

  return (
    <Flex
      pos="relative"
      w={{ base: '19.5rem', md: '38.75rem' }}
      flexDir="column"
      bg={partsSliderGradient}
      px="1rem"
      pb="1.5rem"
      {...props}
    >
      <Flex
        w="full"
        py="0.5rem"
        alignItems="center"
        justifyContent="center"
        bg={partsSliderGradient}
      >
        <Tooltip label={partsDescription[part?.type.name as keyof typeof partsDescription]} openDelay={500}>
          <Heading
            fontFamily="heading"
            color="white.60"
            fontSize="1rem"
            fontWeight={400}
          >
            {part?.type.name}
          </Heading>
        </Tooltip>
      </Flex>

      <Flex my="1rem" mb="1.5rem" justifyContent="space-between">
        <motion.div
          style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
          animate={{ opacity: valueInPercentage < 50 ? 1 : 0.4 }}
        >
          <Flex bg="rgba(255, 255, 255, 0.1)" borderRadius="full" p="1">
            <IconTuningLow transform="scale(1.3)" />
          </Flex>
          <Text
            color="white.80"
            fontSize="0.75rem"
            textTransform="uppercase"
            fontFamily="body"
          >
            Low
          </Text>
        </motion.div>
        <motion.div
          style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
          animate={{ opacity: valueInPercentage > 50 ? 1 : 0.4 }}
        >
          <Flex bg="rgba(255, 255, 255, 0.1)" borderRadius="full" p="1">
            <IconTuningHigh transform="scale(1.3)" />
          </Flex>
          <Text
            color="white.80"
            fontSize="0.75rem"
            textTransform="uppercase"
            fontFamily="body"
          >
            High
          </Text>
        </motion.div>
      </Flex>

      <RSlider
        onChange={(value) => setValue(value)}
        value={value}
        onChangeEnd={(val) => {
          handleSliderOnChangeEnd({
            name: getPartName(part?.type.name || ''),
            val,
          });
        }}
        defaultValue={
          Number(
            userRaceData?.[
            getPartName(part?.type.name || '') as keyof RaceParticipants
            ]
          ) || 0
        }
        min={part?.lowSettingRange || 0}
        max={part?.highSettingRange || 0}
        suggestedMin={getMinSuggestion(part as CarParts)}
        suggestedMax={getMaxSuggestion(part as CarParts)}
      />

      <Flex my="1rem" mt="1.5rem" justifyContent="space-between">
        <motion.div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: '1px',
          }}
          animate={{ opacity: valueInPercentage < 50 ? 1 : 0.4 }}
        >
          <Flex gap={2}>
            <Flex
              bg={valueInPercentage !== 50 ? 'bloodMoon.100' : 'white.100'}
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              w="4"
              h="4"
              pb="2px"
              color="black.60"
              fontSize="1.5rem"
            >
              {valueInPercentage !== 50 ? '-' : <IconDashes />}
            </Flex>
            <Text
              color="white.80"
              fontSize="0.75rem"
              textTransform="uppercase"
              fontFamily="body"
            >
              {partInfos[part?.type.name as PartTypes]?.title1}
            </Text>
          </Flex>
          {partInfos[part?.type.name as PartTypes]?.title2 && (
            <Flex gap={2} opacity={valueInPercentage < 50 ? 1 : 0.4}>
              <Flex
                bg={valueInPercentage !== 50 ? 'baltic.100' : 'white.100'}
                borderRadius="full"
                alignItems="center"
                justifyContent="center"
                w="4"
                pb="2px"
                h="4"
                color="black.60"
                fontSize="1.5rem"
              >
                {valueInPercentage !== 50 ? '+' : <IconDashes />}
              </Flex>
              <Text
                color="white.80"
                fontSize="0.75rem"
                textTransform="uppercase"
                fontFamily="body"
              >
                {partInfos[part?.type.name as PartTypes]?.title2}
              </Text>
            </Flex>
          )}
        </motion.div>
        <motion.div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            gap: '1px',
          }}
          animate={{ opacity: valueInPercentage > 50 ? 1 : 0.4 }}
        >
          <Flex gap={2}>
            <Text
              color="white.80"
              fontSize="0.75rem"
              textTransform="uppercase"
              fontFamily="body"
            >
              {partInfos[part?.type.name as PartTypes]?.title1}
            </Text>
            <Flex
              bg={valueInPercentage !== 50 ? 'baltic.100' : 'white.100'}
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              w="4"
              pb="2px"
              h="4"
              color="black.60"
              fontSize="1.5rem"
            >
              {valueInPercentage !== 50 ? '+' : <IconDashes />}
            </Flex>
          </Flex>
          {partInfos[part?.type.name as PartTypes]?.title2 && (
            <Flex gap={2}>
              <Text
                color="white.80"
                fontSize="0.75rem"
                textTransform="uppercase"
                fontFamily="body"
              >
                {partInfos[part?.type.name as PartTypes]?.title2}
              </Text>
              <Flex
                bg={valueInPercentage !== 50 ? 'bloodMoon.100' : 'white.100'}
                borderRadius="full"
                alignItems="center"
                justifyContent="center"
                w="4"
                h="4"
                pb="2px"
                color="black.60"
                fontSize="1.5rem"
              >
                {valueInPercentage !== 50 ? '-' : <IconDashes />}
              </Flex>
            </Flex>
          )}
        </motion.div>
      </Flex>

      <Box
        pos="absolute"
        w="calc(100% - 0.5rem)"
        h="0.5rem"
        borderColor="white.40"
        borderLeftWidth="0.125rem"
        borderBottomWidth="0.125rem"
        borderRightWidth="0.125rem"
        bottom="0.25rem"
        left="0.25rem"
      />
    </Flex>
  );
};
