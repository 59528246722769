import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Heading,
  Flex,
  ModalBodyProps,
  ModalHeaderProps,
  ModalFooterProps,
} from '@chakra-ui/react';

type Props = {
  heading: React.ReactNode | string | undefined;
  headerContent?: React.ReactNode;
  drawerBody: React.ReactNode;
  drawerHeaderProps?: ModalHeaderProps;
  drawerBodyProps?: ModalBodyProps;
  drawerFooter?: React.ReactNode;
  drawerFooterProps?: ModalFooterProps;
};

const Drawer = ({
  heading,
  headerContent,
  drawerHeaderProps,
  isOpen = false,
  onClose,
  drawerBody,
  drawerBodyProps,
  drawerFooter,
  drawerFooterProps,
  size = 'lg',
  ...rest
}: Omit<DrawerProps, 'children'> & Props) => {
  return (
    <ChakraDrawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={size}
      {...rest}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader {...drawerHeaderProps}>
          <Flex w="full">
            <Heading
              as="h1"
              size="md"
              textTransform="uppercase"
              display="flex"
              alignItems="center"
            >
              {heading}
            </Heading>
            <DrawerCloseButton id="drawer-close-button" />
          </Flex>

          <Flex w="full">{headerContent}</Flex>
        </DrawerHeader>
        {/* TODO Progress */}

        <DrawerBody id="drawerBody" {...drawerBodyProps}>
          {drawerBody}
        </DrawerBody>

        <DrawerFooter {...drawerFooterProps}>{drawerFooter}</DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default Drawer;
