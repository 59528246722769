/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Slider as ChakraSlider,
  SliderThumb,
  SliderTrack,
  SliderProps,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { IconThumbsUp } from 'icons';

const RSlider = ({
  suggestedMin = 0,
  suggestedMax = 0,
  min = 0,
  max = 100,
  onChangeEnd,
  onChange,
  defaultValue,
  ...rest
}: SliderProps & {
  suggestedMin?: number;
  suggestedMax?: number;
}) => {
  const [lastValue, setLastValue] = useState<number>(defaultValue || 0);
  const suggestionWidth = ((suggestedMax - suggestedMin) / (max - min)) * 100;
  const suggestionLeft = ((suggestedMin - min) / (max - min)) * 100;

  const isInsideSuggestion =
    lastValue >= suggestedMin && lastValue <= suggestedMax;
  const lastValueInPercentage = ((lastValue - min) / (max - min)) * 100;

  const handleSliderChange = (value: number) => {
    setLastValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const getClipPath = (percentage: number) => {
    if (percentage <= 0) {
      return 'polygon(0 100%, 100% 50%, 100% 100%)';
    }
    const y = 100 - percentage;

    if (percentage <= 50) {
      const x = percentage * 2;
      return `polygon(${x}% ${y}%, 100% 50%, 100% 100%, ${x}% 100%)`;
    }

    const x = (percentage - 50) * 2;
    return `polygon(0% 50%, ${x}% ${y}%, ${x}% 100%, 0% 100%)`;
  };

  useEffect(() => {
    handleSliderChange(defaultValue || 0);
  }, [defaultValue]);

  return (
    <ChakraSlider
      h="1rem"
      py="2"
      aria-label="tuning-slider"
      min={min}
      max={max}
      defaultValue={defaultValue}
      onChangeEnd={onChangeEnd}
      onChange={handleSliderChange}
      {...rest}
    >
      <SliderTrack bg="neutralDark.80" h="1.5rem">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          h="1.5rem"
          bg="secondaryYellow.40"
          position="absolute"
          top="0"
          style={{
            width: `${suggestionWidth}%`,
            left: `${suggestionLeft}%`,
          }}
          zIndex={2}
        >
          <IconThumbsUp w="0.75rem" />
        </Box>

        <Box
          pos="absolute"
          top="0"
          left={lastValueInPercentage <= 50 ? '0' : '50%'}
          width="50%"
          height="full"
          bg="white.30"
          clipPath={getClipPath(lastValueInPercentage)}
          zIndex={1}
        />

        <Box
          pos="absolute"
          top="0"
          left="0"
          width="full"
          height="full"
          bg="white.10"
          clipPath="polygon(0 100%, 100% 0, 100% 100%)"
          zIndex={1}
        />

        <Box
          position="absolute"
          h="full"
          w="0.063rem"
          bg="white.100"
          top="0"
          left="50%"
        />
        {[...Array(9)].map((_, i) => (
          <Box
            key={i}
            position="absolute"
            top="-1rem"
            h={i === 0 || i === 8 ? '0.5rem' : '0.25rem'}
            w="0.063rem"
            bg="white.20"
            left={`${i * 12.5}%`}
          />
        ))}
        {[...Array(9)].map((_, i) => (
          <Box
            key={i}
            position="absolute"
            bottom="-1rem"
            h={i === 0 || i === 8 ? '0.5rem' : '0.25rem'}
            w="0.063rem"
            bg="white.20"
            left={`${i * 12.5}%`}
          />
        ))}
      </SliderTrack>
      {!rest.isReadOnly && (
        <SliderThumb
          display="flex"
          pos="relative"
          alignItems="center"
          justifyContent="center"
          h="4.5rem"
          w="0.25rem"
          borderRadius="0.25rem"
          bg={isInsideSuggestion ? 'secondaryYellow.100' : 'white.100'}
          zIndex={3}
        >
          <Box
            pos="absolute"
            top="-0.25rem"
            width="0"
            height="0"
            borderLeft="0.5rem solid transparent"
            borderRight="0.5rem solid transparent"
            borderTop="1rem solid"
            borderTopColor={
              isInsideSuggestion ? 'secondaryYellow.100' : 'white.100'
            }
          />
          <Box
            pos="absolute"
            bottom="-0.25rem"
            width="0"
            height="0"
            borderLeft="0.5rem solid transparent"
            borderRight="0.5rem solid transparent"
            borderBottom="1rem solid"
            borderBottomColor={
              isInsideSuggestion ? 'secondaryYellow.100' : 'white.100'
            }
          />
        </SliderThumb>
      )}
    </ChakraSlider>
  );
};

export default RSlider;
