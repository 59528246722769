import { ReactNode, createContext, useContext, useState } from 'react';

interface TuningContextProps {
  brakeCoolingTuning: number | null;
  engineCoolingTuning: number | null;
  frontWingTuning: number | null;
  rearWingTuning: number | null;
  transmissionTuning: number | null;
  handling: number;
  cooling: number;
  speed: number;
  braking: number;
  grip: number;
  fuel: number;
  isLoadingTuning: boolean;
  setIsLoadingTuning: React.Dispatch<React.SetStateAction<boolean>>;
  setBrakeCoolingTuning: (value: number) => void;
  setEngineCoolingTuning: (value: number) => void;
  setFrontWingTuning: (value: number) => void;
  setRearWingTuning: (value: number) => void;
  setTransmissionTuning: (value: number) => void;
  setEngineModeTuning: (value: number) => void;
  setSuspensionRideHeightTuning: (value: number) => void;
  setSuspensionStiffnessTuning: (value: number) => void;
}

const tuningContext = createContext<TuningContextProps>(
  {} as TuningContextProps
);

export const TuningProvider = ({ children }: { children: ReactNode }) => {
  const [brakeCoolingTuning, setBrakeCoolingTuning] = useState<number | null>(
    50
  );
  const [engineCoolingTuning, setEngineCoolingTuning] = useState<number | null>(
    50
  );
  const [frontWingTuning, setFrontWingTuning] = useState<number | null>(50);
  const [rearWingTuning, setRearWingTuning] = useState<number | null>(50);
  const [transmissionTuning, setTransmissionTuning] = useState<number | null>(
    50
  );
  const [engineModeTuning, setEngineModeTuning] = useState<number | null>(50);
  const [suspensionRideHeightTuning, setSuspensionRideHeightTuning] = useState<
    number | null
  >(50);
  const [suspensionStiffnessTuning, setSuspensionStiffnessTuning] = useState<
    number | null
  >(50);
  const [isLoadingTuning, setIsLoadingTuning] = useState<boolean>(false);

  const XFWTuning = frontWingTuning ?? 0;
  const XRW = rearWingTuning ?? 0;
  const XENG = engineModeTuning ?? 0;
  const XTRANS = transmissionTuning ?? 0;
  const XCOOL = engineCoolingTuning ?? 0;
  const XBRAKE = brakeCoolingTuning ?? 0;
  const XRH = suspensionRideHeightTuning ?? 0;
  const XSTIFF = suspensionStiffnessTuning ?? 0;

  const formatToTwoDecimals = (value: number): number => {
    return Number(value.toFixed(2));
  };

  const handling = formatToTwoDecimals(3 + 0.07 * XFWTuning - 0.03 * XRH);
  const cooling = formatToTwoDecimals(3 + 0.07 * XCOOL - 0.028 * XENG);
  const speed = formatToTwoDecimals(
    formatToTwoDecimals(
      7.7375 -
        0.000045 * Math.pow(XENG, 2) +
        0.018 * XENG +
        0.0015 * XTRANS -
        0.0675 * XRW
    )
  );
  const braking = formatToTwoDecimals(1 + 0.08 * XBRAKE);
  const grip = formatToTwoDecimals(4.5 + 0.02 * XRW + 0.005 * XSTIFF);
  const fuel = formatToTwoDecimals(10 - 0.1 * XENG);

  return (
    <tuningContext.Provider
      value={{
        brakeCoolingTuning,
        engineCoolingTuning,
        frontWingTuning,
        rearWingTuning,
        transmissionTuning,
        handling,
        cooling,
        speed,
        braking,
        grip,
        fuel,
        isLoadingTuning,
        setIsLoadingTuning,
        setBrakeCoolingTuning,
        setEngineCoolingTuning: setEngineCoolingTuning,
        setFrontWingTuning: setFrontWingTuning,
        setRearWingTuning: setRearWingTuning,
        setTransmissionTuning: setTransmissionTuning,
        setEngineModeTuning: setEngineModeTuning,
        setSuspensionRideHeightTuning: setSuspensionRideHeightTuning,
        setSuspensionStiffnessTuning: setSuspensionStiffnessTuning,
      }}
    >
      {children}
    </tuningContext.Provider>
  );
};

export const useTuning = () => useContext(tuningContext);
