/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  CrossmintEvent,
  CrossmintPaymentElement,
} from '@crossmint/client-sdk-react-ui';

import { RModal } from 'components';
import { IconInfoCircle, IconDollar, IconCreditCard, IconCrypto } from 'icons';
import { colors } from 'utils/themeConstants';
import { useUserSettings } from 'hooks';
import { useLoadingTrailer, useWallet } from 'context';
import RNumberInput from 'components/NumberInput/NumberInput';

type CryptoTabCommonProps = {
  quantity?: number;
  setQuantity?: (value: number) => void;
};

type CheckoutModalProps<T> = {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  checkoutValue: string;
  onSuccessfulPurchase?: (e: CrossmintEvent) => void;
  collectionId: string;
  projectId: string;
  mintConfig: Record<string, unknown>;
  environment?: 'staging' | 'production';
  numberInput?: boolean;
  quantity?: number;
  setQuantity?: (value: number) => void;
  cryptoTabProps?: T & CryptoTabCommonProps;
  CryptoTab?: React.ComponentType<T & CryptoTabCommonProps>;
};

export const CheckoutModal = <T,>({
  checkoutValue,
  isOpen,
  onClose,
  collectionId,
  projectId,
  mintConfig,
  title,
  onSuccessfulPurchase,
  CryptoTab,
  cryptoTabProps = {} as T & CryptoTabCommonProps,
  environment,
  numberInput,
  quantity = 1,
  setQuantity = () => null,
}: CheckoutModalProps<T>) => {
  const { setIsOperationBlocked } = useWallet();
  const { getUserSettings } = useUserSettings();
  const { data, isLoading: isLoadingUserData } = getUserSettings;
  const { email, walletAddress } = data?.getUser || {};
  const { setIsLoadingTickets } = useLoadingTrailer();

  console.log('EMAIL (not using), wallet:', email, walletAddress);
  console.log('MINCONFIG', mintConfig);

  useEffect(() => {
    setIsOperationBlocked(true);
  }, []);

  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (!isOpen) {
      setIsLoadingTickets(false);
      setQuantity(1);
    }
  }, [isOpen]);

  return (
    <RModal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      variant="feedbackMOdal"
      title={title || 'Buy Car'}
      id="checkout-modal"
    >
      <Flex
        w="full"
        gap="4"
        maxH={'80vh'}
        flexDir={'column'}
        align="center"
        px={8}
        py={8}
        overflowY="auto"
        className="scroll-thin"
      >
        <Tabs w="full">
          <TabList>
            <Tab gap={2} fontSize={{ base: 'xs', md: 'md' }}>
              <IconCreditCard />
              Credit Card
            </Tab>
            {CryptoTab && (
              <Tab gap={2} fontSize={{ base: 'xs', md: 'md' }}>
                <IconCrypto />
                Crypto
              </Tab>
            )}
          </TabList>

          {numberInput && (
            <Flex
              w="full"
              alignItems="center"
              justifyContent="center"
              mt="1.5rem"
            >
              <RNumberInput value={quantity} setValue={setQuantity} />
            </Flex>
          )}

          <TabPanels>
            <TabPanel>
              <Flex align="center" w="full" display="flex" flexDir="column">
                <Tooltip label="This value is the price of your asset plus any applicable fees.">
                  <Flex gap={2} mt="0.5rem">
                    <Text
                      fontFamily="heading"
                      fontSize="lg"
                      textTransform="uppercase"
                    >
                      Cost
                    </Text>
                    <IconInfoCircle
                      width={4}
                      height="auto"
                      __css={{
                        path: {
                          fill: 'white',
                        },
                      }}
                    />
                  </Flex>
                </Tooltip>

                <Flex
                  gap={2}
                  alignItems="end"
                  textColor="aphroditeAqua.100"
                  mb={4}
                >
                  <IconDollar
                    width="1.5rem"
                    height="auto"
                    mb={1}
                    __css={{
                      path: {
                        fill: 'aphroditeAqua.100',
                      },
                    }}
                  />
                  <Heading fontFamily="body" size="2xl" fontWeight="semibold">
                    {price}
                  </Heading>
                  <Text fontFamily="body" fontSize={'md'}>
                    USD
                  </Text>
                </Flex>

                <Flex w="full" minH="448px">
                  {!isLoadingUserData && (
                    <CrossmintPaymentElement
                      collectionId={collectionId}
                      projectId={projectId}
                      mintConfig={mintConfig}
                      environment={environment}
                      recipient={{
                        email,
                        wallet: walletAddress || undefined,
                      }}
                      currency="USD"
                      uiConfig={{
                        colors: {
                          background: 'rgba(0,0,0,0)',
                          backgroundSecondary: '#151517',
                          backgroundTertiary: colors.baltic[100],
                          textPrimary: '#FFF',
                          textSecondary: '#FFF',
                          accent: '#E2645A',
                          danger: '#E2645A',
                          textLink: '#E2645A',
                        },
                        fontSizeBase: '0.875rem',
                        spacingUnit: '0.5rem',
                        borderRadius: '0',
                        fontWeightPrimary: '400',
                        fontWeightSecondary: '500',
                      }}
                      onEvent={(event: CrossmintEvent) => {
                        console.log(event);
                        const paymentStatus = event.type;
                        console.log(paymentStatus);
                        if (paymentStatus === 'payment:process.succeeded') {
                          console.log(event);
                          onSuccessfulPurchase &&
                            onSuccessfulPurchase(event as CrossmintEvent);
                          setIsLoadingTickets(false);
                        }

                        if (paymentStatus === 'quote:status.changed') {
                          console.log(event);
                          setPrice(parseFloat(event.payload.totalPrice.amount));
                          setIsLoadingTickets(false);
                        }

                        if (paymentStatus === 'payment:preparation.failed') {
                          console.log('PREPARE MINT FAILED ---------------');
                          console.log(event);
                        }
                      }}
                    />
                  )}
                </Flex>
              </Flex>
            </TabPanel>
            <TabPanel>
              {CryptoTab && (
                <CryptoTab
                  quantity={quantity}
                  setQuantity={setQuantity}
                  {...(cryptoTabProps as JSX.IntrinsicAttributes &
                    T &
                    CryptoTabCommonProps)}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </RModal>
  );
};
