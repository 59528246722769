import { Flex, Heading, Text } from "@chakra-ui/react"
import { RImage, RPositionAndPoints, RTiers} from "components"
import { AppliedLivery, CarTier, Livery, RarityTier } from "types";

interface PerformanceCarCardProps {
  vehicleName?: string;
  points?: number;
  carPosition?: number;
  livery?: Livery | undefined;
  tier?: CarTier;
  rarity?: RarityTier;
  appliedLivery?: AppliedLivery;
  started?: string;
  finished?: string;
}

const RPerformanceCarCard = ({ vehicleName, points, livery, appliedLivery, carPosition, started, finished, tier, rarity }: PerformanceCarCardProps) => {
  return (
    <Flex flexDir="column" p="4">
      <Flex flexDir="column" h="full">
        <Text
          fontFamily={"heading"}
          fontSize="1.5rem"
        >
          {vehicleName}
        </Text>
        <Flex gap={2}>
          {rarity && <RTiers tierType="driver" tier={rarity} size="xs" />}

          {tier && <RTiers tierType="car" tier={tier || 'bronze'} size="xs" />}

          <RPositionAndPoints
            carPosition={carPosition}
            fontFamily="heading"
            points={points}
          />
        </Flex>
      </Flex>
      <Flex
        position="relative"
        zIndex="1"
        p="4"
        justifyContent="center"
        w="10rem"
        m="0 auto"
        alignItems="center"
        h="full"
      >
        <RImage
          src={
          require(`public/images/cars/livery-${livery}.png`)
        }
          alt=""
          objectPosition="center center"
          position="absolute"
          zIndex="2"
        />

        <RImage
          src={require(`public/images/cars/livery-shadow.png`)}
          alt=""
          position="relative"
          objectPosition="center center"
          zIndex="0"
          filter="blur(2px)"
        />
      </Flex>
      <Flex flexDir="column" gap={2} py="2">
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            fontFamily={"heading"}
            fontSize="0.875rem"
            color="white.60"
          >
            STARTED
          </Text>
          <Text
            background="cerebralGrey.100"
            p="1"
            fontSize="0.8rem"
            textTransform="uppercase"
            w="2.5rem"
            textAlign="center"
            color="black.60"
          >
            {started}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            fontFamily={"heading"}
            fontSize="0.875rem"
            color="white.60"
          >
            FINISHED
          </Text>
          <Text
            background="red"
            p="1"
            textTransform={"uppercase"}
            fontSize="0.8rem"
            w="2.5rem"
            textAlign="center"
          >
            {finished}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RPerformanceCarCard